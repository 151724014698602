import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import apiUrl from './utils'; // Importieren Sie apiUrl
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

class CategoryManagement extends Component {
  state = {
    categories: [],
    newCategory: { name: "", status: "aktiv" },
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    this.setState({ loading: true });
    try {
      console.log("Fetching categories");
      const response = await axios.get(`${apiUrl}/api/categories/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log("Categories fetched:", response.data);
      // Stellen Sie sicher, dass die API-Antwort ein Array ist
      if (Array.isArray(response.data)) {
        this.setState({ categories: response.data, loading: false });
      } else {
        throw new Error("API response is not an array");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      this.setState({ categories: [], error: error.message, loading: false });
    }
  };

  handleInputChange = (e, id, field) => {
    const { categories } = this.state;
    const updatedCategories = categories.map(cat => {
      if (cat.id === id) {
        return { ...cat, [field]: e.target.value };
      }
      return cat;
    });
    this.setState({ categories: updatedCategories });
  };

  handleNewInputChange = (e) => {
    const { newCategory } = this.state;
    this.setState({ newCategory: { ...newCategory, [e.target.name]: e.target.value } });
    console.log(newCategory)
  };

  handleSave = async (id) => {
    const category = this.state.categories.find(cat => cat.id === id);
    try {
      console.log(`Updating category ${id} with data:`, category);
      await axios.put(`${apiUrl}/api/categories/${id}`, category, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log('Category updated successfully.');
      alert('Category updated successfully.');
    } catch (error) {
      console.error("Error updating category:", error);
      alert('Failed to update category.');
    }
  };

  handleDelete = async (id) => {
    try {
      console.log(`Deleting category with id: ${id}`);
      await axios.delete(`${apiUrl}/api/categories/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      this.setState({ categories: this.state.categories.filter(cat => cat.id !== id) });
      console.log('Category deleted successfully.');
      alert('Category deleted successfully.');
    } catch (error) {
      console.error("Error deleting category:", error);
      alert('Failed to delete category.');
    }
  };

  handleCreate = async () => {
    const { newCategory } = this.state;
    try {
      console.log("Creating new category with data:", newCategory);
      const response = await axios.post(`${apiUrl}/api/categories/`, newCategory, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log('Category created successfully:', response.data);
      this.setState({
        newCategory: { name: "", status: "aktiv" },
      });
      this.fetchCategories();
      alert('Category created successfully.');
    } catch (error) {
      console.error("Error creating category:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      alert('Failed to create category.');
    }
  };

  render() {
    const { categories, newCategory, loading, error } = this.state;

    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error}</p>;
    }

    return (
      <div>
        <h2>Produktkategorienverwaltung</h2>
        <Table>
          <thead>
            <tr>
              <TableHeader>ID</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Aktionen</TableHeader>
            </tr>
          </thead>
          <tbody>
            {categories.map(cat => (
              <TableRow key={cat.id}>
                <TableCell>{cat.id}</TableCell>
                <TableCell>
                  <Input
                    type="text"
                    value={cat.name}
                    onChange={(e) => this.handleInputChange(e, cat.id, 'name')}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={cat.status}
                    onChange={(e) => this.handleInputChange(e, cat.id, 'status')}
                  >
                    <option value="aktiv">aktiv</option>
                    <option value="inaktiv">inaktiv</option>
                  </Select>
                </TableCell>
                <TableCell>
                  <button className='button' onClick={() => this.handleSave(cat.id)}>Speichern</button>
                  {' '}
                  <button className='button' onClick={() => this.handleDelete(cat.id)}>Löschen</button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Neu</TableCell>
              <TableCell>
                <Input
                  type="text"
                  name="name"
                  value={newCategory.name}
                  onChange={this.handleNewInputChange}
                />
              </TableCell>
              <TableCell>
                <Select
                  name="status"
                  value={newCategory.status}
                  onChange={this.handleNewInputChange}
                >
                  <option value="aktiv">aktiv</option>
                  <option value="inaktiv">inaktiv</option>
                </Select>
              </TableCell>
              <TableCell>
                <button className='button' onClick={this.handleCreate}>Erstellen</button>
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default CategoryManagement;

// src/components/DeliveryNoteStoreCheck.js

import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Table, Button, ButtonGroup } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import './WasSchBer.css';
import { formatDateGerman } from './utils';
import axios from 'axios';
import apiUrl from './utils';

const DeliveryNoteCheck = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [deliveryDate, setDeliveryDate] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const [Dealer, setDealer] = useState(null);
  const [DealerID, setDealerID] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deliveryNoteStatus, setDeliveryNoteStatus] = useState(null);
  const [productStatus, setProductStatus] = useState({});
  const [deliveredQuantities, setDeliveredQuantities] = useState({});
  const [quantityErrors, setQuantityErrors] = useState({});
  const [deliveryNoteData, setDeliveryNoteData] = useState({});

  useEffect(() => {
    if (id) {
      fetchDeliveryNoteById(id);
    }
  }, [id]);

  const fetchDeliveryNoteById = async (id) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.get(`${apiUrl}/api/delivery_notes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      console.log(data)
      if (data && data.products && Array.isArray(data.products)) {
        setDeliveryDate(data.delivery_date);
        setDealer(data.dealer_name);
        setDealerID(data.dealer_id);
        setSelectedStore({ value: data.store_id, label: data.store_name });
        setSelectedProducts(
          data.products.map((item) => ({
            id: item.id,
            product: { value: item.product_id, label: item.product_name },
            description: item.description,
            hasPhoto: item.has_photo,
            photo: item.product_photo,
            quantity: item.quantity,
            delivery_note_id: id,
            product_id: item.product_id,
            delivery_status: item.delivery_status[0],
            quantity_delivered: item.quantity_delivered || 0,
            product_name: item.product_name
          }))
        );
        setDeliveryNoteStatus(data.status);
        setDeliveryNoteData(data);
        console.log(data)
      } else {
        console.error('Unexpected API response structure:', data);
      }
    } catch (error) {
      console.error('Error fetching delivery note:', error);
    }
  };

  const handleClose = () => {
    navigate('/deliverynotestore');
  };

  const isReadOnly = deliveryNoteStatus === 'In Bearbeitung' || deliveryNoteStatus === 'Abgeschlossen';

  const getStatusVariant = (status) => {
    switch (status) {
      case 'Neu':
        return 'primary';
      case 'In Bearbeitung':
        return 'warning';
      case 'Abgeschlossen':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const handleDeliveryStatus = (productId, status) => {
    setProductStatus((prevStatus) => ({
      ...prevStatus,
      [productId]: status,
    }));

    const plannedQuantity = selectedProducts.find(item => item.id === productId).quantity;

    if (status === 'Geliefert') {
      setDeliveredQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: plannedQuantity,
      }));
    } else if (status === 'Nicht geliefert') {
      setDeliveredQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: 0,
      }));
    } else if (status === 'Teilgeliefert') {
      setDeliveredQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: '',
      }));
    }
    setQuantityErrors((prevErrors) => ({
      ...prevErrors,
      [productId]: '',
    }));
  };

  const handleDeliveredQuantityChange = (productId, value) => {
    const plannedQuantity = selectedProducts.find(item => item.id === productId).quantity;
    if (value >= 0 && value <= plannedQuantity) {
      setDeliveredQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: value,
      }));
      setQuantityErrors((prevErrors) => ({
        ...prevErrors,
        [productId]: '',
      }));
    } else if (value < 0) {
      setQuantityErrors((prevErrors) => ({
        ...prevErrors,
        [productId]: 'Menge (geliefert) darf nicht kleiner als 0 sein',
      }));
    } else {
      setQuantityErrors((prevErrors) => ({
        ...prevErrors,
        [productId]: 'Menge (geliefert) darf nicht größer sein als Menge (geplant)',
      }));
    }
  };

  const canCompleteDeliveryNote = () => {
    return selectedProducts.every(item => productStatus[item.id] && deliveredQuantities[item.id] !== '');
  };

  const handleCompleteDeliveryNote = async () => {
    const token = localStorage.getItem('access_token');
    const newDeliveryNote = {
      ...deliveryNoteData,
      status: 'Abgeschlossen',
      products: selectedProducts.map(item => ({
        ...item,
        delivery_status: productStatus[item.id],
        quantity_delivered: deliveredQuantities[item.id],
      })),
    };

    try {
      await axios.put(`${apiUrl}/api/delivery_notes/${id}`, newDeliveryNote, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });



      navigate('/deliverynotestore');
    } catch (error) {
      console.error('Error completing delivery note:', error);
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h1>{isReadOnly ? 'Lieferschein anzeigen' : 'Lieferschein bearbeiten'}</h1>
          {deliveryNoteStatus && (
            <Button variant={getStatusVariant(deliveryNoteStatus)} disabled className="ml-3">
              {deliveryNoteStatus}
            </Button>
          )}
        </div>
        <div>
          <button className='button' variant="primary" onClick={handleClose}>
            {isReadOnly ? 'Schließen' : 'Lieferschein speichern'}
          </button>{' '}
          <button 
            variant="success" 
            onClick={handleCompleteDeliveryNote} 
            disabled={!canCompleteDeliveryNote()}
            className="button"
          >
            Lieferschein abschließen
          </button>
        </div>
      </div>
      <Form>
        <Form.Group as={Row}>
          <Col sm={6}>
            <Form.Label>Lieferdatum</Form.Label>
            <div>{formatDateGerman(deliveryDate)}</div>
          </Col>
          <Col sm={6}>
            <Form.Label>Händler</Form.Label>
            <div>{Dealer}</div>
          </Col>
        </Form.Group>
        <h3>Produkte</h3>
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Produkt</th>
              <th style={{ width: '30%' }}>Beschreibung</th>
              <th style={{ width: '15%' }}>Foto</th>
              <th style={{ width: '10%' }}>Menge (geplant)</th>
              <th style={{ width: '10%' }}>Menge (geliefert)</th>
              <th style={{ width: '15%' }}>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((item, index) => (
              <tr key={index}>
                <td data-label="Produkt">{item.product.label}</td>
                <td>
                  <pre>{item.description}</pre>
                </td>
                <td data-label="Foto">
                  {item.hasPhoto === 1 ? (
                    <Button className='button' onClick={() => window.open(`${apiUrl}/api/products/${item.product_id}/photo`, '_blank')}>
                      Foto anzeigen
                    </Button>
                  ) : (
                    'Kein Foto'
                  )}
                </td>
                <td data-label="Menge">{item.quantity}</td>
                <td>
                  {productStatus[item.id] === 'Teilgeliefert' ? (
                    <>
                      <Form.Control
                        type="number"
                        value={deliveredQuantities[item.id] || ''}
                        onChange={(e) => handleDeliveredQuantityChange(item.id, Number(e.target.value))}
                        required
                      />
                      {quantityErrors[item.id] && <div className="text-danger">{quantityErrors[item.id]}</div>}
                    </>
                  ) : (
                    deliveredQuantities[item.id]
                  )}
                </td>
                <td>
                  <ButtonGroup>
                    <Button
                      variant={productStatus[item.id] === 'Geliefert' ? 'success' : 'outline-success'}
                      onClick={() => handleDeliveryStatus(item.id, 'Geliefert')}
                    >
                      Geliefert
                    </Button>
                    <Button
                      variant={productStatus[item.id] === 'Teilgeliefert' ? 'warning' : 'outline-warning'}
                      onClick={() => handleDeliveryStatus(item.id, 'Teilgeliefert')}
                    >
                      Teilgeliefert
                    </Button>
                    <Button
                      variant={productStatus[item.id] === 'Nicht geliefert' ? 'danger' : 'outline-danger'}
                      onClick={() => handleDeliveryStatus(item.id, 'Nicht geliefert')}
                    >
                      Nicht geliefert
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>
    </Container>
  );
};

export default DeliveryNoteCheck;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Container, Button, Table, ButtonGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import apiUrl from './utils';
import './WasSchBer.css';
import { formatDateGerman } from './utils';

function AgreementsManagement() {
  const { user } = useAuth();
  const storeEmail = user.email;
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();

  const [agreements, setAgreements] = useState([]);
  const [filteredAgreements, setFilteredAgreements] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [filters, setFilters] = useState(['aktiv', 'bestellt']);
  const [loading, setLoading] = useState(true);
  const [totalSum, setTotalSum] = useState(0); // Neue state-Variable für die Gesamtsumme
  const today = new Date();

  useEffect(() => {
    fetchDealers();
  }, []);

  const fetchDealers = async () => {
    const response = await axios.get(`${apiUrl}/api/dealers/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDealers(response.data);
    fetchAgreements(response.data);
  };

  const fetchAgreements = async (dealersList) => {
    const response = await axios.get(`${apiUrl}/api/agreements/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const sortedAgreements = response.data
      .filter(agreement => {
        const dealer = dealersList.find(dealer => dealer.id === agreement.dealer_id);
        return dealer && (dealer.first_name || dealer.last_name); // Filter out empty names
      })
      .sort((a, b) => {
        const dealerA = dealersList.find(dealer => dealer.id === a.dealer_id);
        const dealerB = dealersList.find(dealer => dealer.id === b.dealer_id);

        if (!dealerA || !dealerB) return 0;

        const nameA = `${dealerA.first_name} ${dealerA.last_name}`.toLowerCase();
        const nameB = `${dealerB.first_name} ${dealerB.last_name}`.toLowerCase();

        return nameA.localeCompare(nameB);
      });

    setAgreements(sortedAgreements);
    setFilteredAgreements(sortedAgreements.filter(agreement => filters.includes(agreement.status)));
    calculateTotalSum(sortedAgreements); // Berechne die Gesamtsumme bei jedem Laden der Vereinbarungen
    setLoading(false);
  };

  const handleFilterClick = (status) => {
    setFilters((prevFilters) =>
      prevFilters.includes(status)
        ? prevFilters.filter((filter) => filter !== status)
        : [...prevFilters, status]
    );
  };

  useEffect(() => {
    applyFilters();
  }, [filters, agreements]);

  const applyFilters = () => {
    setFilteredAgreements(agreements.filter(agreement => filters.includes(agreement.status)));
  };

  const handleAddAgreement = () => {
    navigate('/add-agreement');
  };

  const getStatusVariant = (status) => {
    switch (status) {
      case 'aktiv':
        return 'success';
      case 'bestellt':
        return 'warning';
      case 'abgelaufen':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  // Funktion zur Berechnung der Summe der gültigen Dienstleistungen
  const calculateValidServicesTotal = (services) => {
    return services
      .filter(service => {
        const startDate = new Date(service.start_date);
        const endDate = service.end_date ? new Date(service.end_date) : null;

        return startDate <= today && (!endDate || endDate >= today);
      })
      .reduce((total, service) => total + service.price * service.quantity, 0);
  };

  // Funktion zur Berechnung der Gesamtsumme über alle Vereinbarungen
  const calculateTotalSum = (agreements) => {
    const total = agreements.reduce((sum, agreement) => {
      const validServicesTotal = calculateValidServicesTotal(agreement.services);
      return sum + validServicesTotal;
    }, 0);
    setTotalSum(total);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Vereinbarungen</h1>
        <button className='button btn btn-primary' style={{ fontSize: '24px', padding: '0 12px' }} onClick={handleAddAgreement}>
          +
        </button>
      </div>
      <div className="mb-3">
        <strong>Status-Filter: </strong>
        <ButtonGroup>
          <Button
            variant={filters.includes('aktiv') ? 'success' : 'outline-success'}
            onClick={() => handleFilterClick('aktiv')}
          >
            Aktiv
          </Button>
          <Button
            variant={filters.includes('bestellt') ? 'warning' : 'outline-warning'}
            onClick={() => handleFilterClick('bestellt')}
          >
            Bestellt
          </Button>
          <Button
            variant={filters.includes('abgelaufen') ? 'danger' : 'outline-danger'}
            onClick={() => handleFilterClick('abgelaufen')}
          >
            Abgelaufen
          </Button>
        </ButtonGroup>
      </div>
      <div className="mb-3">
        <strong>Gesamtsumme aller gültigen Dienstleistungen: {totalSum} €</strong> {/* Anzeige der Gesamtsumme */}
      </div>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Händler</th>
            <th>Start</th>
            <th>Ende</th>
            <th>Laufzeit</th>
            <th>Kündigungsfrist</th>
            <th>Status</th>
            <th>Ges.-Summe</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAgreements.map((agreement, index) => {
            const dealer = dealers.find(dealer => dealer.id === agreement.dealer_id);
            const validServicesTotal = calculateValidServicesTotal(agreement.services);

            return (
              <tr key={index}>
                <td>{dealer.first_name} {dealer.last_name} - {dealer.city}</td>
                <td>{formatDateGerman(agreement.start_date)}</td>
                <td>{agreement.end_date ? formatDateGerman(agreement.end_date) : ''}</td>
                <td>{agreement.minimum_duration} Monate</td>
                <td>{agreement.notice_period} Wochen</td>
                <td>
                  <Button variant={getStatusVariant(agreement.status)} disabled>
                    {agreement.status}
                  </Button>
                </td>
                <td>{validServicesTotal} €</td> {/* Anzeige der Summe der gültigen Dienstleistungen */}
                <td>
                  <button className='button' variant="secondary" onClick={() => navigate(`/edit-agreement/${agreement.id}`)}>
                    Aufrufen
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}

export default AgreementsManagement;

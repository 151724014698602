import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import apiUrl from './utils';
import { NotificationContext } from './NotificationContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f4f4f4;
`;


const Box = styled.div`
  padding: 40px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 440px;
  text-align: center;
`;
const Title = styled.h2`
  text-align: center;
  color: #022f40;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SuccessMessage = styled.p`
  color: green;
`;


const ResendActivation = () => {
  const { showNotification } = useContext(NotificationContext);

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
    // URLSearchParams verwenden, um Query-Parameter zu extrahieren
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email'); // Extrahiert den Wert des 'email'-Parameters
  const handleResendActivation = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/users/resend-activation/`, { email });
      showNotification('Neuer Bestätigungslink per E-Mail versendet.', 'success');
      setTimeout(() => {
        navigate('/login');
      }, 3000);      
    } catch (error) {
      showNotification('Die E-Mail konnte nicht versendet werden.', 'error');
    }
  };

  return (
    <Container>
        <Box>
      <Title>Aktivierungsemail erneut versenden</Title>
      <infotext>Dein Account ist noch nicht aktiviert. Schicke dir einfach eine erneute Aktivierungsemail an deine E-Mail-Adresse:</infotext>
      <br></br>
      <b><infotext>{email}</infotext></b>
      {notification && <Notification message={notification} />}
      <form onSubmit={handleResendActivation}>
        {message && <SuccessMessage>{message}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <button className='button' type="submit">E-Mail senden</button>
      </form>
      </Box>
    </Container>
  );
};

export default ResendActivation;

import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import apiUrl from './utils'; // Importieren Sie apiUrl
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import { NotificationContext } from './NotificationContext';

const UserTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  margin-right: 10px;
  padding: 5px;
`;

const PaginationButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  background-color: #022f40; // Elementfarbe
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #76B9AF; // Hover-Farbe
  }
`;

class UserManagement extends Component {
  state = {
    users: [],
    loading: true,
    error: null,
    search: '',
    currentPage: 1,
    usersPerPage: 25,
  };

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = async () => {
    this.setState({ loading: true });
    try {
      const { search, currentPage, usersPerPage } = this.state;
      const response = await axios.get(`${apiUrl}/api/users`, {
        params: { search },
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      this.setState({ users: response.data, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.fetchUsers();
  };

  handleResetPassword = async (email) => {
    try {
      await axios.post(`${apiUrl}/api/users/reset-password/`, { email });
      alert('Passwort zurücksetzen E-Mail versendet.');
    } catch (error) {
      alert('Passwort zurücksetzen E-Mail konnte nicht versendet werden.');
    }
  };

  handleLockUser = async (userId) => {
    try {
      await axios.post(`${apiUrl}/api/users/lock/${userId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('User wurde gesperrt.');
    } catch (error) {
      alert('User konnte nicht gesperrt werden.');
    }
  };

  handleUnlockUser = async (userId) => {
    try {
      await axios.post(`${apiUrl}/api/users/unlock/${userId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('User wurde entsperrt.');
    } catch (error) {
      alert('User konnte nicht entsperrt werden.');
    }
  };

  handleActivateUser = async (userId) => {
    try {
      await axios.post(`${apiUrl}/api/users/activate/${userId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('User wurde aktiviert.');
    } catch (error) {
      alert('User konnte nicht aktiviert werden.');
    }
  };

  handleAddStore = async (userId) => {
    try {
      await axios.put(`${apiUrl}/api/users/${userId}/add-store`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('User wurde zum Store hinzugefügt.');
      this.fetchUsers(); // Aktualisieren Sie die Benutzerliste
    } catch (error) {
      alert('User konnte nicht zum Store hinzugefügt werden.');
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, this.fetchUsers);
  };

  render() {
    const { users, loading, error, search, currentPage, usersPerPage } = this.state;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(users.length / usersPerPage);

    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error}</p>;
    }

    return (
      <div>
        <h2>Benutzerverwaltung</h2>
        <SearchContainer>
          <form onSubmit={this.handleSearchSubmit}>
            <SearchInput
              type="text"
              placeholder="Search by email, name or first name"
              value={search}
              onChange={this.handleSearchChange}
            />
            <button className='button' type="submit">Suchen</button>
          </form>
        </SearchContainer>
        <UserTable>
          <thead>
            <tr>
              <TableHeader>ID</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Vorname</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Typ</TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.status}</TableCell>
                <TableCell>{user.user_type === 1 ? 'Händler' : user.user_type === 2 ? 'Storebetreiber' : 'Händler und Storebetreiber'}</TableCell>
                <TableCell>
                  <button className="button" onClick={() => this.handleResetPassword(user.email)}>Passwort zurücksetzen</button> {' '}
                  {user.status != "locked" && (
                    <button className="button" onClick={() => this.handleLockUser(user.id)}>Sperren</button>
                  )} {' '}
                  {user.status == "locked" && (
                    <button className="button" onClick={() => this.handleUnlockUser(user.id)}>Entsperren</button>
                  )} {' '}
                  {user.status == "new" && (
                    <button className="button" onClick={() => this.handleActivateUser(user.id)}>Aktivieren</button>
                  )} {' '}                  
                  {user.user_type === 1 && (
                    <button className="button" onClick={() => this.handleAddStore(user.id)}>Hat Store</button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </UserTable>
        <div>
          {[...Array(totalPages)].map((_, i) => (
            <PaginationButton key={i} onClick={() => this.handlePageChange(i + 1)}>
              {i + 1}
            </PaginationButton>
          ))}
        </div>
      </div>
    );
  }
}

export default UserManagement;

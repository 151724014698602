import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from './Auth';
import globalState from './globalState';
import apiUrl from './utils';
import './WasSchBer.css';
import { NotificationContext } from './NotificationContext';
import { v4 as uuidv4 } from 'uuid';

const UserSettings = () => {
  const { showNotification } = useContext(NotificationContext);
  const { user } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [settings, setSettings] = useState({
    user_id: '',
    email: '',
    first_name: '',
    last_name: '',
    company_name: '',
    street: '',
    house_number: '',
    postal_code: '',
    city: '',
    mobile_number: '',
    vat_status: 0,
    tax_number: '',
    user_type: 1
  });
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
        if (user) {
            try {
                await delay(500);
                const response = await axios.post(`${apiUrl}/api/get-user-settings`, { token: localStorage.getItem('access_token') });
                
                // Wandeln Sie vat_status von 0/1 in false/true um
                const fetchedSettings = {
                    ...response.data,
                    vat_status: response.data.vat_status === 1
                };

                setSettings(fetchedSettings);

                // Zahlungsmethoden abrufen
                const paymentResponse = await axios.post(`${apiUrl}/api/get-payment-methods`, { token: localStorage.getItem('access_token') });
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    payment_methods: Array.isArray(paymentResponse.data.payment_methods) ? paymentResponse.data.payment_methods : [],
                }));

            } catch (error) {
                setError('Benutzereinstellungen konnten nicht abgerufen werden.');
                showNotification('Benutzereinstellungen konnten nicht geladen werden.', 'error');
            }
        }
    };
    fetchSettings();
}, [user]);



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSaveButtonEnabled(true);
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePaymentMethodChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newPaymentMethods = [...settings.payment_methods];
    newPaymentMethods[index] = {
      ...newPaymentMethods[index],
      [name]: type === 'checkbox' ? checked : value,
    };
    setSettings({ ...settings, payment_methods: newPaymentMethods });
    setSaveButtonEnabled(true);
  };

  const addPaymentMethod = () => {
    setSettings({
      ...settings,
      payment_methods: [
        ...settings.payment_methods,
        { id: 'new', method: '', iban: '', bic: '', paypal_email: '', is_default: false },
      ],
    });
    setSaveButtonEnabled(true);
  };

  const handleUpdatePaymentMethod = async (method) => {
    try {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      await axios.post(`${apiUrl}/api/update-payment-method`, {
        id: method.id,
        method: method.method,
        iban: method.iban,
        bic: method.bic,
        paypal_email: method.paypal_email,
        is_default: method.is_default
      }, config);

      showNotification('Zahlungsmethode erfolgreich aktualisiert.', 'success');
    } catch (error) {
      console.error('Error updating payment method:', error);
      showNotification('Fehler beim Aktualisieren der Zahlungsmethode. Bitte versuchen Sie es erneut.', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const token = localStorage.getItem('access_token');
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        // Sicherstellen, dass vat_status als 0 oder 1 übergeben wird
        const vatStatusValue = settings.vat_status ? 1 : 0;

        // 1. Benutzerinformationen speichern
        await axios.post(`${apiUrl}/api/user-settings`, {
            user_id: settings.user_id,
            first_name: settings.first_name,
            last_name: settings.last_name,
            company_name: settings.company_name,
            street: settings.street,
            house_number: settings.house_number,
            postal_code: settings.postal_code,
            city: settings.city,
            mobile_number: settings.mobile_number,
            vat_status: vatStatusValue,
            tax_number: String(settings.tax_number),
            email: settings.email,
            user_type: globalState.user_type
        }, config);

        // 2. Zahlungsmethoden speichern oder aktualisieren
        for (const method of settings.payment_methods) {
          const isDefaultValue = method.is_default ? 1 : 0;
          console.log(method.id)
            if (method.id.startsWith('new')) {
                // Neue Zahlungsmethode speichern
                await axios.post(`${apiUrl}/api/save-payment-method`, {
                    id: method.id,
                    user_id: settings.user_id,
                    method: method.method,
                    iban: method.iban,
                    bic: method.bic,
                    paypal_email: method.paypal_email,
                    is_default: isDefaultValue
                }, config);
            } else {
                // Bestehende Zahlungsmethode aktualisieren
                await axios.post(`${apiUrl}/api/update-payment-method`, {
                    id: method.id,
                    method: method.method,
                    iban: method.iban,
                    bic: method.bic,
                    paypal_email: method.paypal_email,
                    is_default: isDefaultValue
                }, config);
            }
        }

        setSaveButtonEnabled(false);
        showNotification('Einstellungen erfolgreich gespeichert.', 'success');
    } catch (error) {
        console.error('Error saving settings:', error);
        showNotification('Fehler beim Speichern der Einstellungen. Bitte versuchen Sie es erneut.', 'error');
    }
};


  return (
    <div className="settings-container">
      <h2>Benutzereinstellungen</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      <form onSubmit={handleSubmit}>
      
        <h3>Allgemeine Daten</h3>
        <div className="payment-method-group">
        <div className="form-group">
          <label>Benutzer-ID:</label>
          <input type="text" name="user_id" value={settings.user_id} readOnly />
        </div>
        <div className="form-group">
          <label>E-Mail:</label>
          <input type="text" name="email" value={settings.email} readOnly />
        </div>
        </div>
        
        <h3>Persönliche Daten</h3>
        <div className="payment-method-group">
        <div className="form-group">
          <label>Vorname:</label>
          <input type="text" name="first_name" value={settings.first_name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Nachname:</label>
          <input type="text" name="last_name" value={settings.last_name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Firmenname:</label>
          <input type="text" name="company_name" value={settings.company_name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Straße:</label>
          <input type="text" name="street" value={settings.street} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Hausnummer:</label>
          <input type="text" name="house_number" value={settings.house_number} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Postleitzahl:</label>
          <input type="text" name="postal_code" value={settings.postal_code} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Stadt:</label>
          <input type="text" name="city" value={settings.city} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Mobilnummer:</label>
          <input type="text" name="mobile_number" value={settings.mobile_number} onChange={handleChange} />
        </div>
        </div>
        
        <h3>Steuerdaten</h3>
        <div className="payment-method-group">
        <div className="form-group">
          <label>
            Umsatzsteuerpflichtig:
            <input
              type="checkbox"
              name="vat_status"
              checked={settings.vat_status}
              onChange={handleChange}
            />
          </label>
          </div>
        <div className="form-group">
          <label>Steuer-Nr.:</label>
          <input type="text" name="tax_number" value={settings.tax_number} onChange={handleChange} />
        </div>
        </div>
        <h3>Zahlungsinformationen</h3>
        {Array.isArray(settings.payment_methods) && settings.payment_methods.map((method, index) => (
          <div key={method.id} className="payment-method-group">
            <div className="form-group">
              <label>Zahlungsmethode:</label>
              <select
                name="method"
                value={method.method}
                onChange={(e) => handlePaymentMethodChange(index, e)}
                required
              >
                <option value="">-- Bitte wählen --</option>
                <option value="Bank">Bank</option>
                <option value="PayPal">PayPal</option>
              </select>
            </div>
            
            {method.method === 'Bank' && (
              <>
                <div className="form-group">
                  <label>IBAN:</label>
                  <input
                    type="text"
                    name="iban"
                    value={method.iban}
                    onChange={(e) => handlePaymentMethodChange(index, e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>BIC:</label>
                  <input
                    type="text"
                    name="bic"
                    value={method.bic}
                    onChange={(e) => handlePaymentMethodChange(index, e)}
                    required
                  />
                </div>
              </>
            )}

            {method.method === 'PayPal' && (
              <div className="form-group">
                <label>PayPal E-Mail:</label>
                <input
                  type="email"
                  name="paypal_email"
                  value={method.paypal_email}
                  onChange={(e) => handlePaymentMethodChange(index, e)}
                  required
                />
              </div>
            )}

            <div className="form-group">
              <label>
                Standardmethode:
                <input
                  type="checkbox"
                  name="is_default"
                  checked={method.is_default}
                  onChange={(e) => handlePaymentMethodChange(index, e)}
                />
              </label>
            </div>
          </div>
        ))}
        <button className='button' type="button" onClick={addPaymentMethod}>
          Zahlungsmethode hinzufügen
        </button>

        <button
          className='button'
          type="submit"
          disabled={!saveButtonEnabled}
        >
          Speichern
        </button>
      </form>
    </div>
  );
};

export default UserSettings;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Container, Button, Form, Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './Auth'; // Correct import of useAuth
import apiUrl from './utils';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei

function AddAgreement() {
  const { user } = useAuth();
  const storeEmail = user.email;
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const { id } = useParams(); // Get the agreement ID from the URL

  const [selectedDealer, setSelectedDealer] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [minimumDuration, setMinimumDuration] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [customNumber, setCustomNumber] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (id) {
      fetchAgreement(id);
    }
    fetchServices();
  }, [id]);

  const fetchAgreement = async (id) => {
    const response = await axios.get(`${apiUrl}/api/agreements/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const agreement = response.data;
    setSelectedDealer({ value: agreement.dealer_id, label: `${agreement.dealer_first_name} ${agreement.dealer_last_name} - ${agreement.dealer_city}` });
    setStartDate(agreement.start_date);
    setEndDate(agreement.end_date);
    setMinimumDuration(agreement.minimum_duration);
    setNoticePeriod(agreement.notice_period);
    setCustomNumber(agreement.custom_number);
    setStatus(agreement.status);
    setSelectedServices(agreement.services.map(service => ({
      value: service.service_id,
      label: service.service_name,
      id: service.id,
      quantity: service.quantity,
      status: service.status,
      agreement_id: id,
      start_date: service.start_date,
      end_date: service.end_date,
      price: service.price, // Preisfeld nur lesen
      service_id: service.service_id,
      name: service.service_name
    })));
  };

  const fetchDealers = async (inputValue) => {
    if (inputValue.length < 3) {
      return [];
    }

    const response = await axios.get(`${apiUrl}/api/dealers/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: inputValue,
      },
    });

    return response.data.map(dealer => ({
      value: dealer.id,
      label: `${dealer.first_name} ${dealer.last_name} - ${dealer.city}`
    }));
  };

  const fetchServices = async () => {
    const response = await axios.get(`${apiUrl}/api/services/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setServices(response.data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newAgreement = {
      id: '',
      dealer_id: selectedDealer.value,
      start_date: startDate,
      end_date: endDate,
      minimum_duration: parseInt(minimumDuration),
      notice_period: parseInt(noticePeriod),
      store_email: storeEmail,
      custom_number: customNumber,
      status: status,
      services: selectedServices.map(service => ({
        service_id: service.service_id,
        name: service.name || '',
        quantity: service.quantity,
        start_date: service.start_date,
        end_date: service.end_date,
        agreement_id: id,
        status: status,
        id: service.id || '',
      }))
    };

    if (id) {
      await axios.put(`${apiUrl}/api/agreements/${id}`, newAgreement, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      await axios.post(`${apiUrl}/api/agreements/`, newAgreement, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    //avigate('/agreements');
  };

  const handleAddService = () => {
    // Dienstleistung wird mit Startdatum der Vereinbarung vorbelegt
    setSelectedServices([...selectedServices, { value: '', quantity: 1, start_date: startDate, end_date: '', price: 0 }]);
  };

  const handleRemoveService = async (index, serviceId) => {
    if (serviceId) {
      await axios.delete(`${apiUrl}/api/agreements/${id}/services/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    const newServices = selectedServices.filter((_, i) => i !== index);
    setSelectedServices(newServices);
  };

  const handleServiceChange = (index, selectedOption) => {
    // Automatische Zuweisung des Preises nach Auswahl der Dienstleistung
    const selectedService = services.find(service => service.id === selectedOption.value);
    const newServices = selectedServices.map((service, i) => {
      if (i === index) {
        return {
          ...service,
          value: selectedOption.value,
          label: selectedOption.label,
          service_id: selectedOption.value,
          name: selectedOption.label,
          price: selectedService ? selectedService.price : 0 // Preis setzen
        };
      }
      return service;
    });
    setSelectedServices(newServices);
  };

  const handleQuantityChange = (index, quantity) => {
    const newServices = selectedServices.map((service, i) => {
      if (i === index) {
        return { ...service, quantity: parseInt(quantity) };
      }
      return service;
    });
    setSelectedServices(newServices);
  };

  const handleStartDateChange = (index, startDate) => {
    if (new Date(startDate) < new Date(startDate) || (endDate && new Date(startDate) > new Date(endDate))) {
      alert('Das Startdatum muss zwischen dem Start- und Enddatum der Vereinbarung liegen.');
      return;
    }
    const newServices = selectedServices.map((service, i) => {
      if (i === index) {
        return { ...service, start_date: startDate };
      }
      return service;
    });
    setSelectedServices(newServices);
  };

  const handleEndDateChange = (index, endDate) => {
    const serviceStartDate = selectedServices[index].start_date;
    if (new Date(endDate) < new Date(serviceStartDate)) {
      alert('Das Enddatum muss nach dem Startdatum liegen.');
      return;
    }
    if (new Date(endDate) > new Date(endDate)) {
      alert('Das Enddatum muss zwischen dem Start- und Enddatum der Vereinbarung liegen.');
      return;
    }
    const newServices = selectedServices.map((service, i) => {
      if (i === index) {
        return { ...service, end_date: endDate };
      }
      return service;
    });
    setSelectedServices(newServices);
  };

  const serviceOptions = services.map(service => ({
    value: service.id,
    label: service.name
  }));

  const handleSaveService = async (service) => {
    const serviceData = {
      id: service.id || '',
      agreement_id: id,
      service_id: service.value,
      quantity: service.quantity,
      start_date: service.start_date,
      end_date: service.end_date,
      price: service.price, // Füge den Preis hinzu
      status: status,
      name: service.label || ''
    };

    if (service.id) {
      await axios.put(`${apiUrl}/api/agreements/${id}/services/${service.id}`, serviceData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      const response = await axios.post(`${apiUrl}/api/agreements/${id}/services`, serviceData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      service.id = response.data.id;
    }
  };

  // Handler zum Schließen der Ansicht und Rückkehr zur Übersicht
  const handleClose = () => {
    navigate('/agreements'); // Leitet zurück zur Übersicht
  };

  return (
    <Container>
      <h1>{id ? 'Vereinbarung ändern' : 'Vereinbarung anlegen'}</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Händler</Form.Label>
          {id ? (
            <Form.Control type="text" value={selectedDealer?.label} readOnly />
          ) : (
            <AsyncSelect
              loadOptions={fetchDealers}
              value={selectedDealer}
              onChange={setSelectedDealer}
              placeholder="Daten eingeben um Suche zu starten..."
              isSearchable
              required
            />
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Start</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </Form.Group>   
        <Form.Group>
          <Form.Label>Ende (optional)</Form.Label>
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Form.Group>            
        <Form.Group>
          <Form.Label>Mindestlaufzeit in Monaten</Form.Label>
          <Form.Control
            type="number"
            value={minimumDuration}
            onChange={(e) => setMinimumDuration(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Kündigungsfrist in Wochen</Form.Label>
          <Form.Control
            type="number"
            value={noticePeriod}
            onChange={(e) => setNoticePeriod(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>interne Nummer</Form.Label>
          <Form.Control
            type="text"
            value={customNumber}
            onChange={(e) => setCustomNumber(e.target.value)}
          />
        </Form.Group>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Dienstleistungen</h3>
          <Button className="btn btn-primary" onClick={handleAddService}>
            +
          </Button>
        </div>

        <Table bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Dienstleistung</th>
              <th>Startdatum</th>
              <th>Enddatum</th>
              <th>Preis</th>
              <th>Anzahl</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {selectedServices.map((service, index) => (
              <tr key={index}>
                <td>{service.service_id}</td>
                <td>
                  <Select
                    options={serviceOptions}
                    value={serviceOptions.find(option => option.value === service.value)}
                    onChange={(selectedOption) => handleServiceChange(index, selectedOption)}
                    placeholder="Select a service..."
                    isSearchable
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="date"
                    value={service.start_date}
                    onChange={(e) => handleStartDateChange(index, e.target.value)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="date"
                    value={service.end_date || ''}
                    onChange={(e) => handleEndDateChange(index, e.target.value)}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={service.price}
                    readOnly // Preisfeld nicht editierbar
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={service.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    min="1"
                    required
                  />
                </td>
                <td>
                  <button className='button' variant="success" onClick={() => handleSaveService(service)}>Speichern</button>{' '}
                  <button className='button' variant="danger" onClick={() => handleRemoveService(index, service.id)}>Entfernen</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <br></br>
        <button className='button' variant="primary" type="submit">Vereinbarung speichern</button>
        <br></br>
        <button className='button' variant="secondary" onClick={handleClose}>Schließen</button>
      </Form>
    </Container>
  );
}

export default AddAgreement;

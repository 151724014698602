// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './Auth';
import AppRoutes from './AppRoutes';
import Footer from './Footer';
import styled from 'styled-components';
import globalState from './globalState';
import { NotificationProvider } from './NotificationContext';
import { TrackingProvider } from './TrackingContext'; // Importiere den TrackingProvider
/* import Notification from './Notification'; */
import './Notification.css'; // Importiere die Notification CSS
import CookieConsentModal from './CookieConsentModal';


const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
  background: #f4f4f4;
`;

const App = () => {
  const [isCookieConsentGiven, setIsCookieConsentGiven] = useState(hasUserConsented());
  useEffect(() => {
    globalState.setUserID();
  }, []);

  const handleAcceptCookies = () => {
    setConsentCookie();
    setIsCookieConsentGiven(true);
  };

  const handleDeclineCookies = () => {
    setConsentCookieDeclined()
    console.log('Nutzer hat Cookies abgelehnt');
    setIsCookieConsentGiven(true); // Setze true, um das Modal auszublenden
  };

  return (
    <Router>
      <AuthProvider>
        <TrackingProvider>
          <NotificationProvider>
            <PageLayout>
              <AppRoutes />
              <Footer />
{/*               {!isCookieConsentGiven && (
                <CookieConsentModal onAccept={handleAcceptCookies} onDecline={handleDeclineCookies} />
              )}      */}         
            </PageLayout>
          </NotificationProvider>
        </TrackingProvider>
      </AuthProvider>
    </Router>
  );
};


function hasUserConsented() {
  const consentCookie = document.cookie.split('; ').find(row => row.startsWith('cookieConsent='));
  return consentCookie && consentCookie.split('=')[1] === 'true';
}

function setConsentCookie() {
  document.cookie = 'cookieConsent=true; path=/; max-age=' + 60 * 60 * 24 * 365; // Gültigkeit: 1 Jahr
}

function setConsentCookieDeclined() {
  document.cookie = 'cookieConsent=false; path=/; max-age=' + 60 * 60 * 24 * 365; // Gültigkeit: 1 Jahr
}
export default App;



// src/components/DeliveryNoteStore.js

import React, { useState, useEffect } from 'react';
import { Container, Table, ButtonGroup, Button, Pagination } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import { useNavigate } from 'react-router-dom';
import { formatDateGerman } from './utils'; // Importieren der Formatierungsfunktion für die Darstellung
import axios from 'axios';
import apiUrl from './utils'; // Importieren der API-URL
import globalState from './globalState'; // Importieren des globalen Zustands

const DeliveryNoteStore = () => {
  const [deliveryNotes, setDeliveryNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filters, setFilters] = useState(['In Bearbeitung']); // Standardfilter 'In Bearbeitung'
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDeliveryNotes();
  }, []);

  const fetchDeliveryNotes = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await axios.get(`${apiUrl}/api/delivery_notes/store/${globalState.userID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setDeliveryNotes(data);
      applyFilters(data); // Filtern der Daten sofort nach dem Abrufen
      setLoading(false);
    } catch (error) {
      console.error('Error fetching delivery notes:', error);
      setLoading(false);
    }
  };

  const handleFilterClick = (status) => {
    setFilters((prevFilters) =>
      prevFilters.includes(status)
        ? prevFilters.filter((filter) => filter !== status)
        : [...prevFilters, status]
    );
  };

  useEffect(() => {
    applyFilters(deliveryNotes);
  }, [filters]);

  const applyFilters = (notes) => {
    let filtered = notes.filter(note => filters.includes(note.status));
    setFilteredNotes(filtered);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const getStatusVariant = (status) => {
    switch (status) {
      case 'In Bearbeitung':
        return 'warning';
      case 'Abgeschlossen':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleView = (id) => {
    navigate(`/deliverynotestore-check/${id}`);
  };

  // Logic for displaying current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNotes.slice(indexOfFirstItem, indexOfLastItem);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredNotes.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination className="custom-pagination">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {pageNumbers.map((number) => (
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageNumbers.length} />
        <Pagination.Last onClick={() => handlePageChange(pageNumbers.length)} disabled={currentPage === pageNumbers.length} />
      </Pagination>
    );
  };

  return (
    <Container>
      <h1>Lieferscheinübersicht für Storebetreiber</h1>
      <div className="mb-3">
        <div>
          <strong>Status-Filter: </strong>
          <ButtonGroup>
            <Button
              variant={filters.includes('In Bearbeitung') ? 'warning' : 'outline-warning'}
              onClick={() => handleFilterClick('In Bearbeitung')}
            >
              In Bearbeitung
            </Button>
            <Button
              variant={filters.includes('Abgeschlossen') ? 'success' : 'outline-success'}
              onClick={() => handleFilterClick('Abgeschlossen')}
            >
              Abgeschlossen
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <th>Lieferschein-Nr.</th>
            <th>Lieferdatum</th>
            <th>Dealer</th>
            <th>Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">
                <Skeleton count={10} />
              </td>
            </tr>
          ) : currentItems.length > 0 ? (
            currentItems.map((note) => (
              <tr key={note.id}>
                <td>{note.id}</td>
                <td>{formatDateGerman(note.delivery_date)}</td>
                <td>{note.dealer_name}</td>
                <td>
                  <Button variant={getStatusVariant(note.status)} disabled>
                    {note.status}
                  </Button>
                </td>
                <td>
                  <button className='button'
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleView(note.id)}
                  >
                    Ansicht
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Keine Lieferscheine gefunden</td>
            </tr>
          )}
        </tbody>
      </Table>
      {renderPagination()}
    </Container>
  );
};

export default DeliveryNoteStore;

// src/TrackingContext.js
import React, { createContext, useContext, useCallback } from 'react';


// Erstelle den TrackingContext
const TrackingContext = createContext();

// TrackingProvider-Komponente
export const TrackingProvider = ({ children }) => {
    // Funktion, die Ereignisse trackt
    const trackEvent = useCallback((eventName, eventData) => {
        if (hasUserConsented()) {
            // Hier kannst du dein Tracking-Tool aufrufen (z. B. Google Analytics, Segment, etc.)
            console.log('Track event:', eventName, eventData);
            console.log(getBrowserInfo());
            // Beispiel: window.analytics.track(eventName, eventData);
        } else {
            console.log('Tracking wurde abgelehnt.');
        }
    }, []);

    return (
        <TrackingContext.Provider value={{ trackEvent }}>
            {children}
        </TrackingContext.Provider>
    );
};

// Hook zum Verwenden des Tracking-Kontextes
export const useTracking = () => {
    return useContext(TrackingContext);
};

function getBrowserInfo() {
    const userAgent = navigator.userAgent;

    let browserName = 'Unbekannter Browser';
    if (userAgent.indexOf('Firefox') > -1) {
        browserName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        browserName = 'Opera';
    } else if (userAgent.indexOf('Edg') > -1) {
        browserName = 'Microsoft Edge';
    } else if (userAgent.indexOf('Chrome') > -1) {
        browserName = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
        browserName = 'Apple Safari';
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
        browserName = 'Internet Explorer';
    }

    return browserName;
}


function hasUserConsented() {
    const consentCookie = document.cookie.split('; ').find(row => row.startsWith('cookieConsent='));
    return consentCookie && consentCookie.split('=')[1] === 'true';
  }
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import apiUrl from './utils';

const Container = styled.div`
  padding: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
`;

function LogsLogin() {
  const [logs, setLogs] = useState([]);
  
  const [startDate, setStartDate] = useState(new Date(Date.now() - 86400000).toISOString().split('T')[0]);
  const [startTime, setStartTime] = useState("00:00:00");

  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [endTime, setEndTime] = useState("23:59:59");

  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState('all');

  const fetchLogs = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/logs-login`, {
        params: {
          start_date: `${startDate} ${startTime}`,
          end_date: `${endDate} ${endTime}`,
          user_id: userId,
          status: status === 'all' ? undefined : status,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      setLogs(response.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <Container>
      <h2>Login-Versuche anzeigen</h2>
      
      <InputContainer>
        <div>
          <label>Startdatum:</label>
          <Input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label>Startzeit:</label>
          <Input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
      </InputContainer>

      <InputContainer>
        <div>
          <label>Enddatum:</label>
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div>
          <label>Endzeit:</label>
          <Input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
      </InputContainer>

      <InputContainer>
        <div>
          <label>User ID (optional):</label>
          <Input
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="User ID eingeben"
          />
        </div>
        <div>
          <label>Status:</label>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="all">Alle</option>
            <option value="successful">Erfolgreich</option>
            <option value="unsuccessful">Nicht erfolgreich</option>
          </Select>
        </div>
      </InputContainer>

      <Button onClick={fetchLogs}>Logs abrufen</Button>
      
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Timestamp</th>
            <th>Status</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              <td>{log.id}</td>
              <td>{log.user_id}</td>
              <td>{log.timestamp}</td>
              <td>{log.result}</td>
              <td>{log.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default LogsLogin;

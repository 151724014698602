import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import apiUrl from './utils';
import globalState from './globalState';
import './WasSchBer.css';
import { formatDateGerman } from './utils';



const AgreementCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  flex: 1;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const CardTitle = styled.h4`
  margin-top: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusBadge = styled.span`
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  ${(props) => {
    switch (props.status) {
      case 'bestellt':
        return 'background-color: #FFD700;';
      case 'aktiv':
        return 'background-color: #228B22;';
      case 'gekündigt':
        return 'background-color: #B22222;';
      default:
        return 'background-color: #777;';
    }
  }}
`;

const Text = styled.p`
  color: black;
  text-align: left;
`;

const InfoContainer = styled.div`
  margin-bottom: 20px;
`;

const IconText = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  a {
    color: black;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const calculateNoticeDate = (startDate, minimumDuration, noticePeriod) => {
  let currentDate = new Date(); // Heutiges Datum
  let originalEndDate = new Date(startDate);
  
  // Mindestlaufzeit hinzufügen (ursprüngliches Enddatum)
  originalEndDate.setMonth(originalEndDate.getMonth() + minimumDuration);

  let noticeDate = new Date(originalEndDate);
  noticeDate.setDate(noticeDate.getDate() - noticePeriod * 7);  // Kündigungsfrist (z.B. 2 Wochen)

  // Prüfen, ob wir in der Kündigungsfrist vor dem ursprünglichen Enddatum liegen
  while (currentDate > noticeDate) {
    // Wenn das heutige Datum die Kündigungsfrist überschreitet, verlängern wir um die Mindestlaufzeit
    originalEndDate.setMonth(originalEndDate.getMonth() + minimumDuration);

    // Kündigungsfrist neu berechnen, basierend auf dem neuen Enddatum
    noticeDate = new Date(originalEndDate);
    noticeDate.setDate(noticeDate.getDate() - noticePeriod * 7);
  }

  // Rückgabe der Kündigungsfrist
  return noticeDate.toISOString().split('T')[0];  // Rückgabe im Format "YYYY-MM-DD"
};

// Berechnung der Gesamtsumme nur für gültige Dienstleistungen
const calculateTotalForNextBilling = (services) => {
  const today = new Date();

  return services
    .filter((service) => {
      const serviceStartDate = new Date(service.start_date);
      const serviceEndDate = service.end_date ? new Date(service.end_date) : null;
      return serviceStartDate <= today && (!serviceEndDate || serviceEndDate >= today);
    })
    .reduce((total, service) => total + (service.price * service.quantity), 0);
};


// Funktion zum Subtrahieren von Wochen von einem Datum
const subtractWeeksFromDate = (dateStr, weeks) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() - weeks * 7);
  return date.toISOString().split('T')[0];
};
const AgreementsView = () => {
  const [agreements, setAgreements] = useState([]);
  const [error, setError] = useState('');
  const { storeEmail } = useParams();

  useEffect(() => {
    const fetchAgreements = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/agreements/dealer/${globalState.userID}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        setAgreements(response.data);
        console.log(response.data);
      } catch (error) {
        setError('Failed to fetch agreements');
        console.error('Error fetching agreements:', error);
      }
    };

    fetchAgreements();
  }, [storeEmail]);

  const requestService = async (agreementId, serviceName) => {
    try {
      const response = await axios.post(`${apiUrl}/api/agreements/${agreementId}/services`, {
        name: serviceName,
        status: 'requested',
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      alert('Service requested successfully');
      console.log(response.data);
      const updatedAgreements = agreements.map(agreement =>
        agreement.id === agreementId ? { ...agreement, services: [...agreement.services, response.data] } : agreement
      );
      setAgreements(updatedAgreements);
    } catch (error) {
      console.error('Failed to request service', error);
      setError('Failed to request service');
    }
  };

  const terminateService = async (agreementId, serviceId) => {
    try {
      const response = await axios.put(`${apiUrl}/api/agreements/${agreementId}/services/${serviceId}/terminate`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('Service terminated successfully');
      console.log(response.data);
      const updatedAgreements = agreements.map(agreement =>
        agreement.id === agreementId ? {
          ...agreement,
          services: agreement.services.map(service =>
            service.service_id === serviceId ? { ...service, status: 'terminated' } : service
          )
        } : agreement
      );
      setAgreements(updatedAgreements);
    } catch (error) {
      console.error('Failed to terminate service', error);
      setError('Failed to terminate service');
    }
  };

  const terminateAgreement = async (agreementId) => {
    try {
      const response = await axios.put(`${apiUrl}/api/agreements/${agreementId}/terminate`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('Agreement terminated successfully');
      console.log(response.data);
      const updatedAgreements = agreements.map(agreement =>
        agreement.id === agreementId ? { ...agreement, status: 'terminated' } : agreement
      );
      setAgreements(updatedAgreements);
    } catch (error) {
      console.error('Failed to terminate agreement', error);
      setError('Failed to terminate agreement');
    }
  };

  const downloadPDF = (agreementId) => {
    alert(`PDF for agreement ${agreementId} will be downloaded.`);
  };

  return (
<Container>
      <h2>Meine Vereinbarungen</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {agreements.map((agreement) => {
        const today = new Date();
        // Berechnung der Kündigungsfrist unter Berücksichtigung der Verlängerung
        const noticeDate = calculateNoticeDate(agreement.start_date, agreement.minimum_duration, agreement.notice_period);
        // Berechnung des Enddatums (leeres Ende-Datum wird als '—' dargestellt)
        const displayEndDate = agreement.end_date ? formatDateGerman(agreement.end_date) : '—';
        // Berechnung der Gesamtsumme für nächste Abrechnung (nur gültige Dienstleistungen)
        const totalForNextBilling = calculateTotalForNextBilling(agreement.services);

        return (
          <AgreementCard key={agreement.id}>
            <TitleContainer>
              <div>
                <h3>Vereinbarung mit {agreement.store_name} vom {formatDateGerman(agreement.start_date)}</h3>
                <Text>
                  {agreement.store_street} {agreement.store_housenumber}, {agreement.store_zip_code} {agreement.store_city}{' '}
                  <IconText>
                    <a href={`mailto:${agreement.store_email}`}>
                      <FaEnvelope />
                    </a>
                    <a href={`tel:${agreement.store_phone}`}>
                      {' '}<FaPhone />
                    </a>
                  </IconText>
                </Text>
              </div>
              <StatusBadge status={agreement.status}>{agreement.status}</StatusBadge>
            </TitleContainer>

            <FlexContainer>
              <Card>
                <CardTitle>Laufzeitinformationen</CardTitle>
                <Text>Startdatum: {formatDateGerman(agreement.start_date)}</Text>
                <Text>Enddatum: {agreement.end_date ? formatDateGerman(agreement.end_date) : '—'}</Text>
                <Text>Laufzeit (Monate): {agreement.minimum_duration}</Text>
                <Text>Kündigungsfrist (Wochen): {agreement.notice_period} (Kündigen bis: {formatDateGerman(noticeDate)})</Text>
                <Text>Solange du die Vereinbarung nicht aktiv kündigst, wird diese automatisch verlängert.</Text>
              </Card>
            </FlexContainer>

            <Card>
              <CardTitle>
                Dienstleistungen
                <span style={{ float: 'right' }}>Gesamtsumme für nächste Abrechnung: {totalForNextBilling.toFixed(2)} €</span>
              </CardTitle>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Beschreibung</th>
                    <th>Startdatum</th>
                    <th>Enddatum</th>
                    <th>Preis</th>
                    <th>Anzahl</th>
                    <th>Gesamtpreis</th>
                  </tr>
                </thead>
                <tbody>
                  {agreement.services.map((service) => (
                    <tr key={service.service_id}>
                      <td data-label="Name">{service.name}</td>
                      <td data-label="Beschreibung">
                        <Form.Label>Beschreibung</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          rows={2}
                          value={service.description}
                          disabled
                        />
                      </td>
                      <td data-label="Startdatum">{formatDateGerman(service.start_date)}</td>
                      <td data-label="Enddatum">{service.end_date ? formatDateGerman(service.end_date) : '—'}</td>
                      <td data-label="Preis">{service.price} €</td>
                      <td data-label="Anzahl">{service.quantity}</td>
                      <td data-label="Gesamtpreis">{service.price * service.quantity} €</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </AgreementCard>
        );
      })}
    </Container>
  );
};

export default AgreementsView;

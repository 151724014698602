import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from './Auth';  // Angenommen, Sie haben einen Auth-Kontext

const AdminRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user || (!user.email.endsWith('@wasschber.de') && !user.email.endsWith('@schmidt-it.services'))) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default AdminRoute;

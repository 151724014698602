// src/components/BillingOverview.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import apiUrl, { openPdf } from './utils';
import globalState from './globalState';
import { NotificationContext } from './NotificationContext';

const BillingOverview = () => {
  const { showNotification } = useContext(NotificationContext);
  const [billings, setBillings] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showModal, setShowModal] = useState(false);
  const [billingMonth, setBillingMonth] = useState(new Date().getMonth() === 0 ? 12 : new Date().getMonth());
  const [billingYear, setBillingYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();

  const monthOptions = [
    { value: 0, label: 'Gesamtes Jahr' },
    { value: 1, label: 'Januar' },
    { value: 2, label: 'Februar' },
    { value: 3, label: 'März' },
    { value: 4, label: 'April' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Juni' },
    { value: 7, label: 'Juli' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'Oktober' },
    { value: 11, label: 'November' },
    { value: 12, label: 'Dezember' },
  ];

  useEffect(() => {
    fetchBillings();
  }, [selectedMonth, selectedYear]);

  const fetchBillings = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/billing/get`, {
        month: selectedMonth,
        year: selectedYear,
        store_email: globalState.email,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log('Fetched Billings:', response.data.billing_data); // Debugging
      setBillings(response.data.billing_data);
    } catch (error) {
      console.error('Error fetching billings:', error);
    }
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleCreateBilling = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/billing/create`, {
        month: billingMonth,
        year: billingYear,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      if (response.data.message === "Abrechnung erfolgreich erstellt") {
        fetchBillings();
        handleCloseModal();
        showNotification(`Es wurden insgesamt ${response.data.billing_details.length} Abrechnung(en) erstellt.`, 'success');
      } else {
        fetchBillings();
        handleCloseModal();
        showNotification(`Es wurden keine Abrechnungen erstellt.`, 'error');
      }
    } catch (error) {
      console.error('Error creating billing:', error);
    }
  };

  const handleOpenSettings = () => {
    navigate('/invoice-settings');  // Navigiere zu den Invoice Settings
  };
  

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center">
        <h1>Abrechnungsübersicht</h1>
        <button className="button" onClick={handleOpenSettings}>
          Nummernkreis anpassen
        </button>        
        <button className="button" onClick={handleShowModal}>
          Abrechnung erstellen
        </button>
      </div>
      <Form>
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Monat</Form.Label>
              <Select
                options={monthOptions}
                value={monthOptions.find(option => option.value === selectedMonth)}
                onChange={handleMonthChange}
                placeholder="Monat auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Jahr</Form.Label>
              <Form.Control as="select" value={selectedYear} onChange={handleYearChange}>
                {[...Array(new Date().getFullYear() - 2019).keys()].map(y => (
                  <option key={2020 + y} value={2020 + y}>{2020 + y}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <th>Rechnungs-Nr.</th>
            <th>Händlername</th>
            <th>Beleg-Datum</th>
            <th>Monat</th>
            <th>Jahr</th>
            <th>Summe Dienstleistungen</th>
            <th>Summe Verkäufe</th>
            <th>Belegsumme</th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          {billings.length > 0 ? billings.map((billing) => (
            <tr key={billing.id}>
              <td>{billing.invoice_number}</td>
              <td>{billing.dealer_name}</td>
              <td>{billing.created_at}</td>
              <td>{billing.month}</td>
              <td>{billing.year}</td>
              <td>{billing.total_services.toFixed(2)} €</td>
              <td>{billing.total_sales.toFixed(2)} €</td>
              <td>{billing.total_amount.toFixed(2)} €</td>
              <td>
              <button className='button' onClick={() => openPdf(billing.pdf)}>
                  PDF anzeigen
                </button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="9">Keine Daten verfügbar</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Abrechnung erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Abrechnungsmonat</Form.Label>
              <Select
                options={monthOptions}
                value={monthOptions.find(option => option.value === billingMonth)}
                onChange={(selectedOption) => setBillingMonth(selectedOption.value)}
                placeholder="Monat auswählen..."
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Abrechnungsjahr</Form.Label>
              <Form.Control
                as="select"
                value={billingYear}
                onChange={(e) => setBillingYear(parseInt(e.target.value))}
              >
                {[...Array(new Date().getFullYear() - 2019).keys()].map(y => (
                  <option key={2020 + y} value={2020 + y}>{2020 + y}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Abbrechen
          </Button>
          <Button className="button" onClick={handleCreateBilling}>
            Abrechnung erstellen
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BillingOverview;

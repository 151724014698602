import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Korrigierter Import
import axios from 'axios';
import apiUrl from './utils';
import globalState from './globalState';

export const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUser({ email: decoded.sub, id: decoded.user_id, isAdmin: decoded.isAdmin, user_type: decoded.user_type });
                
                console.log('User authenticated:', isAuthenticated);  // Debugging
                // Fetch additional user data if needed
                axios.get(`${apiUrl}/api/dashboard/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then(response => {
                    setUser(prevUser => ({
                        ...prevUser,
                        email: response.data.email,
                        id: response.data.user_id,
                        isAdmin: response.data.isAdmin,
                        user_type: response.data.user_type,
                    }));
                    
                }).catch(error => {
                    console.error('Fehler beim Abrufen der UserID:', error);
                });
            } catch (error) {
                console.error('Invalid token:', error);
                logout();
            }
        }
    }, []);

    const login = (token) => {
        const decoded = jwtDecode(token);
        setUser({ email: decoded.sub, id: decoded.user_id, isAdmin: decoded.isAdmin, user_type: decoded.user_type });
        setIsAuthenticated(true);
        localStorage.setItem('access_token', token);
        navigate('/dashboard');
    };

    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('access_token');
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

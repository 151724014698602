// src/components/DeliveryNoteStoreCheck.js

import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Row, Col, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import './WasSchBer.css';
import { formatDateGerman } from './utils';
import axios from 'axios';
import apiUrl from './utils';
import globalState from './globalState';
import { NotificationContext } from './NotificationContext';

const DeliveryNoteCreation = () => {
  const { showNotification } = useContext(NotificationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [deliveryDate, setDeliveryDate] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([{ product: null, quantity: 1 }]);
  const [deliveryNoteStatus, setDeliveryNoteStatus] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    fetchStores();
    fetchProducts();
    if (id) {
      fetchDeliveryNoteById(id);
    }
  }, [id]);

  const fetchStores = async () => {
    const token = localStorage.getItem('access_token');
    const response = await axios.get(`${apiUrl}/api/stores`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setStores(response.data);
  };

  const fetchProducts = async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await axios.get(`${apiUrl}/api/products/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dealer_id: globalState.userID,
        },
      });
  
      // Filtere die Produkte, um nur aktive Produkte zu behalten und sortiere sie alphabetisch nach ihrem Namen
      const activeSortedProducts = response.data
        .filter(product => product.status === 'active')
        .sort((a, b) => a.name.localeCompare(b.name));
  
      // Setze die gefilterten und sortierten Produkte in den Zustand
      setProducts(activeSortedProducts);
    } catch (error) {
      console.error('Fehler beim Abrufen der Produkte:', error);
    }
  };
  
  

  const fetchDeliveryNoteById = async (id) => {
    const token = localStorage.getItem('access_token');
    const response = await axios.get(`${apiUrl}/api/delivery_notes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;

    if (data && data.products && Array.isArray(data.products)) {
      setDeliveryDate(data.delivery_date);
      setSelectedStore({ value: data.store_id, label: data.store_name });
      setSelectedProducts(
        data.products.map((item) => ({
          product: { value: item.product_id, label: item.product_name },
          quantity: item.quantity,
          delivery_note_id: id,
          product_id: item.product_id
        }))
      );
      setDeliveryNoteStatus(data.status);
    } else {
      console.error('Unexpected API response structure:', data);
    }
  };

  const handleAddProduct = () => {
    setSelectedProducts([...selectedProducts, { product: null, quantity: 1 }]);
    setIsDirty(true);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);
    setIsDirty(true);
  };

  const handleProductChange = (index, selectedOption) => {
    const updatedProducts = selectedProducts.map((item, i) =>
      i === index ? { ...item, product: selectedOption, product_id: selectedOption.value } : item
    );
    setSelectedProducts(updatedProducts);
    setIsDirty(true);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedProducts = selectedProducts.map((item, i) =>
      i === index ? { ...item, quantity: parseInt(quantity) } : item
    );
    setSelectedProducts(updatedProducts);
    setIsDirty(true);
  };

  const handleClose = () => {
    navigate('/deliverynote');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!deliveryDate || !selectedStore || selectedProducts.some(sp => !sp.product || !sp.quantity)) {
      alert('Bitte füllen Sie alle erforderlichen Felder aus.');
      return;
    }
    
    const newDeliveryNote = {
      id: null,
      delivery_date: deliveryDate,
      store_id: selectedStore.value,
      store_name: selectedStore.label,
      products: selectedProducts.map(item => ({
        product_id: item.product.value,
        product_name: item.product.label,
        quantity: item.quantity,
        delivery_note_id: null,
        id: null
      })),
      status: 'Neu',
    };
    console.log(newDeliveryNote)
    const token = localStorage.getItem('access_token');
    try {
      if (id) {
        await axios.put(`${apiUrl}/api/delivery_notes/${id}`, newDeliveryNote, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          
        })
        showNotification('Lieferschein erfolgreich aktualisiert.', 'success');
      } else {
        const response = await axios.post(`${apiUrl}/api/delivery_notes`, newDeliveryNote, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        showNotification('Lieferschein erfolgreich erstellt.', 'success');;
        console.log(response);
        navigate(`/deliverynote-edit/${response.data.id}`);
      }
      setIsDirty(false);
      
    } catch (error) {
      console.error('Error saving delivery note:', error);
      alert('Es gab einen Fehler beim Speichern des Lieferscheins.');
    }
  };

  const handleSendToStore = async () => {

    const newDeliveryNote = {
      id: null,
      delivery_date: deliveryDate,
      store_id: selectedStore.value,
      store_name: selectedStore.label,
      products: selectedProducts.map(item => ({
        product_id: item.product.value,
        product_name: item.product.label,
        quantity: item.quantity,
        delivery_note_id: null,
        id: null
      })),
      status: 'In Bearbeitung',
    };
    const token = localStorage.getItem('access_token');
    try {
        await axios.put(`${apiUrl}/api/delivery_notes/${id}`, newDeliveryNote, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      showNotification('Lieferschein wurde zum Store geschickt und der Store per E-Mail informiert.', 'success');
      setIsDirty(false);
    } catch (error) {
      console.error('Error saving delivery note:', error);
      alert('Es gab einen Fehler beim Speichern des Lieferscheins.');
    }
    handleClose();

  };

  const isReadOnly = deliveryNoteStatus === 'In Bearbeitung' || deliveryNoteStatus === 'Abgeschlossen';

  const getStatusVariant = (status) => {
    switch (status) {
      case 'Neu':
        return 'primary';
      case 'In Bearbeitung':
        return 'warning';
      case 'Abgeschlossen':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const handleAddAllProducts = () => {
    // Filtere nur aktive Produkte heraus, die den Status 'active' haben
    const activeProducts = products.filter(product => product.status === 'active');
  
    // Kopiere die aktuelle Liste der ausgewählten Produkte
    const updatedSelectedProducts = [...selectedProducts];
  
    // Für jedes aktive Produkt, füge es zur Liste der ausgewählten Produkte hinzu
    activeProducts.forEach(product => {
      // Erstelle ein neues Produktfeld
      const newProductEntry = {
        product: { value: product.id, label: product.name },
        quantity: 1,
        product_id: product.id
      };
  
      // Füge den neuen Eintrag zur Liste hinzu
      updatedSelectedProducts.push(newProductEntry);
    });
  
    // Aktualisiere die Liste der ausgewählten Produkte im Zustand
    setSelectedProducts(updatedSelectedProducts);
    setIsDirty(true);
  
    showNotification('Alle aktiven Produkte wurden hinzugefügt.', 'info');
  };
  
  

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h1>{isReadOnly ? 'Lieferschein anzeigen' : (id ? 'Lieferschein bearbeiten' : 'Lieferschein erstellen')}</h1>
          {deliveryNoteStatus && (
            <Button variant={getStatusVariant(deliveryNoteStatus)} disabled className="ml-3">
              {deliveryNoteStatus}
            </Button>
          )}
        </div>
        <div>
          {isReadOnly ? (
            <button className='button' variant="secondary" onClick={handleClose}>
              Schließen
            </button>
          ) : (
            <>
              <button className='button' variant="primary" onClick={handleSubmit} disabled={!isDirty}>
                Lieferschein speichern
              </button>{' '}
              <button className='button' variant="secondary" onClick={handleClose}>
                Schließen
              </button>{' '}
              {deliveryNoteStatus === 'Neu' && (
                <button className='button' variant="primary" onClick={handleSendToStore} disabled={isDirty}>
                  Lieferschein zu Store senden
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Col sm={6}>
            <Form.Label>Lieferdatum</Form.Label>
            <Form.Control
              type="date"
              value={deliveryDate}
              onChange={(e) => {
                setDeliveryDate(e.target.value);
                setIsDirty(true);
              }}
              required
              disabled={isReadOnly}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Store</Form.Label>
            <Select
              options={stores.map(store => ({ value: store.id, label: store.name }))}
              value={selectedStore}
              onChange={(option) => {
                setSelectedStore(option);
                setIsDirty(true);
              }}
              placeholder="Store auswählen..."
              isSearchable
              required
              isDisabled={isReadOnly}
            />
          </Col>
        </Form.Group>
        <h3>Produkte</h3>
        <Table bordered>
          <thead>
            <tr>
              <th>Produkt</th>
              <th>Menge</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((item, index) => (
              <tr key={index}>
                <td>
                  <Select
                    options={products.map(product => ({ value: product.id, label: product.name }))}
                    value={item.product}
                    onChange={(selectedOption) => handleProductChange(index, selectedOption)}
                    placeholder="Produkt auswählen..."
                    isSearchable
                    required
                    isDisabled={isReadOnly}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    min="1"
                    required
                    disabled={isReadOnly}
                  />
                </td>
                <td>
                  {!isReadOnly && (
                    <button className='button' onClick={() => handleRemoveProduct(index)}>
                      Entfernen
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex flex-column align-items-start mt-3">
          {!isReadOnly && (
            <>
              <div className="d-flex">
                <button type="button" className="button me-2" variant="secondary" onClick={handleAddProduct}>
                  Produkt hinzufügen
                </button>
                <button type="button" className="button" variant="secondary" onClick={handleAddAllProducts}>
                  Alle Produkte hinzufügen
                </button>
              </div>
              <p className="text-muted mt-1">Hinweis: Es werden nur aktive Produkte übernommen.</p>
            </>
          )}
        </div>

      </Form>
    </Container>
  );
};

export default DeliveryNoteCreation;

import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './WasSchBer.css';
import globalState from './globalState';
import apiUrl from './utils';

const InventoryOverview = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [stores, setStores] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showCorrectionModal, setShowCorrectionModal] = useState(false);
  const [correctionMerchant, setCorrectionMerchant] = useState(null);
  const [correctionProduct, setCorrectionProduct] = useState(null);
  const [correctionQuantity, setCorrectionQuantity] = useState(0);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const searchParams = new URLSearchParams(location.search);
  const viewType = searchParams.get('viewType');
  const userId = searchParams.get('user_id');

  useEffect(() => {
    fetchSettings();
    fetchFilters();
    fetchProducts();
  }, []);

  useEffect(() => {
    if (storeId !== null) {
      fetchInventory();
    }
  }, [storeId]);

  useEffect(() => {
    applyFilters();
  }, [selectedStore, selectedMerchant, selectedCategory, searchTerm]);

  useEffect(() => {
    applySorting();
  }, [sortConfig]);



  const fetchSettings = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/get-store-settings`, { user_id: globalState.userID }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      setStoreId(response.data.id);
    } catch (error) {
      console.error('Fehler beim Abrufen der Store-Einstellungen:', error);
    }
  };

  const fetchFilters = async () => {
    try {
      const storeResponse = await axios.get(`${apiUrl}/api/stores`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setStores(storeResponse.data);

      const merchantResponse = await axios.get(`${apiUrl}/api/dealers/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setMerchants(merchantResponse.data);

      const categoryResponse = await axios.get(`${apiUrl}/api/categories/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setCategories(categoryResponse.data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const productResponse = await axios.get(`${apiUrl}/api/products`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setProducts(productResponse.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchInventory = async () => {
    try {
      let response;
      if (viewType === 'merchant') {
        response = await axios.get(`${apiUrl}/api/inventory/merchant/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
      } else {
        response = await axios.get(`${apiUrl}/api/inventory/store/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
      }
      setInventoryData(response.data);
      setFilteredInventory(response.data);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  const applyFilters = () => {
    let filtered = inventoryData.filter(item => {
      const matchesStore = !selectedStore || item.store === selectedStore.label;
      const matchesMerchant = !selectedMerchant || item.merchant === selectedMerchant.label;
      const matchesCategory = !selectedCategory || item.category === selectedCategory.label;
      const matchesSearchTerm = item.name.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesStore && matchesMerchant && matchesCategory && matchesSearchTerm;
    });

    setFilteredInventory(filtered);
    applySorting(filtered);
  };


  const applySorting = (data = filteredInventory) => {
    if (sortConfig.key) {
      const sortedData = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setFilteredInventory(sortedData);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption);
    applyFilters();
  };

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption);
    applyFilters(); // Updated: Apply filters when merchant is selected
  };


  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    applyFilters();
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  // Modal Handlers
  const handleOpenCorrectionModal = () => setShowCorrectionModal(true);
  const handleCloseCorrectionModal = () => setShowCorrectionModal(false);

  const handleSaveCorrection = async () => {
    if (!correctionMerchant || !correctionProduct || correctionQuantity === 0) {
      console.error('Fehlende Angaben zur Bestandskorrektur.');
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/inventory/add`, {
        product_id: correctionProduct.value,  // ID des ausgewählten Produkts
        movement_type: 'IN',  // Immer OUT
        quantity: correctionQuantity,  // Die eingegebene Menge
        context_origin: 'Korrektur',  // Kontext für die Korrektur
        context_id: 'manuelle Korrektur',  // ID für die manuelle Korrektur
        store_id: 1,  // Die aktuelle Store-ID
        dealer_id: correctionMerchant.value  // ID des ausgewählten Händlers
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });

      console.log('Bestand erfolgreich geändert:', response.data);
      setShowCorrectionModal(false);
      fetchInventory(); // Aktualisiere den Bestand nach der Korrektur
    } catch (error) {
      console.error('Fehler beim Speichern der Bestandskorrektur:', error);
    }
  };


  const handleMerchantChangeInModal = async (selectedOption) => {
    // Check if a valid merchant is selected
    if (!selectedOption) {
      console.log('Selection cleared or no merchant selected.');
      setCorrectionMerchant(null);  // Reset the merchant selection in the modal
      return;  // Exit the function if no merchant is selected
    }

    // Log the valid selection
    console.log('Selected merchant:', selectedOption);

    // Set the selected merchant
    setCorrectionMerchant(selectedOption);

    // Fetch products for the selected merchant to populate the product select in the modal
    try {
      const response = await axios.get(`${apiUrl}/api/products/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        params: {
          dealer_id: selectedOption.value // Use merchant.id as dealer_id in the request
        }
      });

      // Update the product list for the modal product selection
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products for the selected merchant in modal:', error);
    }
  };





  return (
    <Container>
      <h1>Bestandsübersicht</h1>
      <Form>
        <Row>
          {/* Filter */}
          {viewType === 'merchant' ? (
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Store</Form.Label>
                <Select
                  options={stores.map(store => ({ value: store.id, label: store.name }))}
                  value={selectedStore}
                  onChange={handleStoreChange}
                  placeholder="Store auswählen..."
                  isClearable
                />
              </Form.Group>
            </Col>
          ) : (
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Händler</Form.Label>
                <Select
                  options={merchants.map(merchant => ({
                    value: merchant.dealer_userid,
                    label: `${merchant.first_name} ${merchant.last_name} - ${merchant.city}`
                  }))}
                  value={selectedMerchant}
                  onChange={handleMerchantChange}
                  placeholder="Händler auswählen..."
                  isClearable
                />
              </Form.Group>
            </Col>
          )}
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Produktsuche</Form.Label>
              <Form.Control
                type="text"
                placeholder="Produktname eingeben..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group>
              <Form.Label>Kategorie</Form.Label>
              <Select
                options={categories.map(category => ({ value: category.id, label: category.name }))}
                value={selectedCategory}
                onChange={handleCategoryChange}
                placeholder="Kategorie auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {/* Button for Bestandskorrektur if viewType is store */}
      {viewType === 'store' && (
        <button className='button' variant="primary" onClick={handleOpenCorrectionModal}>
          Bestandskorrektur
        </button>
      )}

      <Table bordered className="mt-3">
        <thead>
          <tr>
            {viewType === 'merchant' ? <th onClick={() => handleSort('store')}>Store {getSortIndicator('store')}</th> : <th onClick={() => handleSort('merchant')}>Händler {getSortIndicator('merchant')}</th>}
            <th onClick={() => handleSort('name')}>Name {getSortIndicator('name')}</th>
            <th onClick={() => handleSort('category')}>Kategorie {getSortIndicator('category')}</th>
            <th onClick={() => handleSort('delivered')}>Geliefert {getSortIndicator('delivered')}</th>
            <th onClick={() => handleSort('sold')}>Verkauft {getSortIndicator('sold')}</th>
            <th onClick={() => handleSort('currentStock')}>Aktueller Bestand {getSortIndicator('currentStock')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredInventory.map((item) => (
            <tr key={item.id}>
              {viewType === 'merchant' ? <td data-label="Store">{item.store}</td> : <td data-label="Händler">{item.merchant}</td>}
              <td data-label="Name">{item.name}</td>
              <td data-label="Kategorie">{item.category}</td>
              <td data-label="Geliefert">{item.delivered}</td>
              <td data-label="Verkauft">{item.sold}</td>
              <td data-label="Aktueller Bestand">{item.delivered - item.sold}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Bestandskorrektur */}
      <Modal show={showCorrectionModal} onHide={handleCloseCorrectionModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bestandskorrektur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>

            <Form.Label>Händler auswählen</Form.Label>
            <Select
              options={merchants.map(merchant => ({
                value: merchant.dealer_userid,
                label: `${merchant.first_name} ${merchant.last_name} - ${merchant.city}`
              }))}
              value={correctionMerchant}
              onChange={handleMerchantChangeInModal} // Ensure this handler is correctly attached
              placeholder="Händler auswählen..."
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Produkt auswählen</Form.Label>
            <Select
              options={products.map(product => ({
                value: product.id,
                label: product.name
              }))}
              value={correctionProduct}
              onChange={setCorrectionProduct}
              placeholder="Produkt auswählen..."
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Menge</Form.Label>
            <Form.Control
              type="number"
              value={correctionQuantity}
              onChange={(e) => setCorrectionQuantity(parseInt(e.target.value))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className='button' variant="secondary" onClick={handleCloseCorrectionModal}>
            Abbrechen
          </button>
          <button className='button' variant="primary" onClick={handleSaveCorrection}>
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InventoryOverview;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { useAuth } from './Auth';
import globalState from './globalState';
import { NotificationContext } from './NotificationContext';
import './WasSchBer.css';
import apiUrl from './utils';

function ProductManagement() {
  const { showNotification } = useContext(NotificationContext);
  const { user } = useAuth();
  const token = localStorage.getItem('access_token');

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    dealer_id: globalState.userID,
    name: '',
    category_id: '',
    description: '',
    photo: null,
    price: '',
    status: 'active'
  });

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = async () => {
    const response = await axios.get(`${apiUrl}/api/products/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        dealer_id: globalState.userID
      }
    });
    setProducts(response.data);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/categories/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleFileChange = (e) => {
    setNewProduct({ ...newProduct, photo: e.target.files[0] });
  };

  const handleInputChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
  };

  const handleEditFileChange = (e) => {
    setEditProduct({ ...editProduct, photo: e.target.files[0] });
  };

  const handleEditInputChange = (e, product) => {
    const { name, value } = e.target;
  
    let updatedValue = value;
  
    // Nur für das Preisfeld eine Eingabeprüfung durchführen, aber keine Formatierung erzwingen
    if (name === 'price') {
      const regex = /^\d*\.?\d{0,2}$/; // Erlaubt Zahlen mit bis zu zwei Dezimalstellen
      if (!regex.test(value)) {
        return; // Abbrechen, wenn die Eingabe ungültig ist
      }
    }
  
    // Aktualisierung des editProduct-Zustands
    setEditProduct(prevState => ({
      ...prevState,
      [name]: updatedValue,
    }));
  
    // Aktualisieren Sie das entsprechende Produkt in der Produktliste
    setProducts(products.map(p => (p.id === product.id ? { ...p, [name]: updatedValue } : p)));
  };
  
  
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newProduct.name || !newProduct.category_id || !newProduct.price) {
      showNotification('Bitte füllen Sie alle Pflichtfelder aus.', 'error');
      return;
    }
    const formData = new FormData();
    formData.append('dealer_id', newProduct.dealer_id);
    formData.append('name', newProduct.name);
    formData.append('category_id', newProduct.category_id);
    formData.append('description', newProduct.description || '');
    formData.append('price', newProduct.price);
    formData.append('status', newProduct.status);
    if (newProduct.photo) {
      formData.append('photo', newProduct.photo);
    }

    try {
      await axios.post(`${apiUrl}/api/products/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      showNotification(`Produkt ${newProduct.name} erfolgreich erstellt.`, 'success');
      fetchProducts();
      setNewProduct({
        dealer_id: globalState.userID,
        name: '',
        category_id: '',
        description: '',
        photo: null,
        price: '',
        status: 'active'
      });
    } catch (error) {
      showNotification('Fehler beim Erstellen des Produkts.', 'error');
    }
  };

  const handleEditSubmit = async (e, product) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('dealer_id', product.dealer_id);
    formData.append('name', product.name);
    formData.append('category_id', product.category_id);
    formData.append('description', product.description || '');
    formData.append('price', product.price);
    formData.append('status', product.status);
    if (editProduct && editProduct.photo) {
      formData.append('photo', editProduct.photo);
    }

    try {
      await axios.put(`${apiUrl}/api/products/${product.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      fetchProducts();
      showNotification(`Produkt erfolgreich aktualisiert.`, 'success');
    } catch (error) {
      showNotification('Fehler beim Aktualisieren des Produkts.', 'error');
    }
  };

  const handleEditClick = (product) => {
    setEditProduct(product);
  };

  const handleDeletePhoto = async (product) => {
    try {
      await axios.delete(`${apiUrl}/api/products/${product.id}/photo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchProducts();
      showNotification('Foto erfolgreich gelöscht.', 'success');
    } catch (error) {
      showNotification('Fehler beim Löschen des Fotos.', 'error');
    }
  };
  {/* Kopieren-Button WSBCSS-55*/}
  const handleCopyProduct = async (product) => {
    // Neuen Produktzustand basierend auf dem kopierten Produkt erstellen
    const copiedProduct = {
      ...product,
      name: `${product.name} (Kopie)`,  // Optionale Anpassung des Namens, um Kopien zu kennzeichnen
      id: undefined,  // Entfernen Sie die ID, damit ein neues Produkt erstellt wird
    };
  
    // FormData für das neue Produkt erstellen
    const formData = new FormData();
    formData.append('dealer_id', copiedProduct.dealer_id);
    formData.append('name', copiedProduct.name);
    formData.append('category_id', copiedProduct.category_id);
    formData.append('description', copiedProduct.description || '');
    formData.append('price', copiedProduct.price);
    formData.append('status', copiedProduct.status);
    if (copiedProduct.photo) {
      formData.append('photo', copiedProduct.photo);
    }
  
    try {
      // API-Anfrage zum Erstellen des kopierten Produkts
      await axios.post(`${apiUrl}/api/products/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      showNotification(`Produkt ${copiedProduct.name} erfolgreich kopiert.`, 'success');
      fetchProducts();  // Aktualisieren Sie die Produktliste
    } catch (error) {
      showNotification('Fehler beim Kopieren des Produkts.', 'error');
    }
  };
  

  return (
    <Container>
      <h1>Produktverwaltung</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Kategorie</th>
            <th>Beschreibung</th>
            <th>Preis (€)</th>
            <th>Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td data-label="ID">{product.custom_number}</td>
              <td>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editProduct && editProduct.id === product.id ? editProduct.name : product.name}
                  onChange={(e) => handleEditInputChange(e, product)}
                />
              </td>
              <td>
                <Form.Label>Kategorie</Form.Label>
                <Form.Control
                  as="select"
                  name="category_id"
                  value={editProduct && editProduct.id === product.id ? editProduct.category_id : product.category_id}
                  onChange={(e) => handleEditInputChange(e, product)}
                >
                  <option value="">Kategorie auswählen</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td>
                <Form.Label>Beschreibung</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  rows={2}
                  value={editProduct && editProduct.id === product.id ? editProduct.description : product.description}
                  onChange={(e) => handleEditInputChange(e, product)}
                />
              </td>
              <td>
                <Form.Label>Preis (€)</Form.Label>
                
                <Form.Control
                  type="number"
                  name="price"
                  step="0.01"
                  value={editProduct && editProduct.id === product.id ? editProduct.price : product.price}
                  onChange={(e) => handleEditInputChange(e, product)}
                />
              </td>
              <td>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={editProduct && editProduct.id === product.id ? editProduct.status : product.status}
                  onChange={(e) => handleEditInputChange(e, product)}
                >
                  <option value="active">Aktiv</option>
                  <option value="inactive">Inaktiv</option>
                </Form.Control>
              </td>
              <td>
                {product.has_photo ? (
                  <>
                    <button className='button' onClick={() => window.open(`${apiUrl}/api/products/${product.id}/photo`, '_blank')}>
                      Foto anzeigen
                    </button>{' '}
                    <button className='button' onClick={() => handleDeletePhoto(product)}>
                      Foto löschen
                    </button>{' '}
                  </>
                ) : (
                  <Form.Control 
                    type="file"
                    custom
                    onChange={handleEditFileChange}
                  />
                )}
                <button className='button' onClick={(e) => handleEditSubmit(e, product)}>
                  Speichern
                </button>{' '}
                {/* Kopieren-Button WSBCSS-55*/}
                <button className='button' onClick={() => handleCopyProduct(product)}>
                  Kopieren
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>Neu</td>
            <td>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newProduct.name}
                onChange={handleInputChange}
                placeholder="Produktname"
              />
            </td>
            <td>
              <Form.Label>Kategorie</Form.Label>
              <Form.Control
                as="select"
                name="category_id"
                value={newProduct.category_id}
                onChange={handleInputChange}
              >
                <option value="">Kategorie auswählen</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </td>
            <td>
              <Form.Label>Beschreibung</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                name="description"
                value={newProduct.description}
                onChange={handleInputChange}
                placeholder="Produktbeschreibung"
              />
            </td>
            <td>
              <Form.Label>Preis (€)</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={newProduct.price}
                onChange={handleInputChange}
                placeholder="Preis in €"
              />
            </td>
            <td>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newProduct.status}
                onChange={handleInputChange}
              >
                <option value="active">Aktiv</option>
                <option value="inactive">Inaktiv</option>
              </Form.Control>
            </td>
            <td>
              <Form.Control 
                type="file"
                custom
                onChange={handleFileChange}
              />
              <button className='button' onClick={handleSubmit}>
                Hinzufügen
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default ProductManagement;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import apiUrl from './utils';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f4f4f4;
`;

const Box = styled.div`
  padding: 40px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 440px;
  text-align: center;
`;
const Title = styled.h2`
  text-align: center;
  color: #022f40;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SuccessMessage = styled.p`
  color: green;
`;

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwörter stimmen nicht überein.');
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/users/password-reset/confirm/`, {
        token,
        new_password: newPassword,
      });
      setMessage(response.data.message);
      setError('');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setError(error.response.data.detail || 'Something went wrong');
    }
  };

  return (
    <Container>
        <Box>
      <Title>Passwort zurücksetzen</Title>
      <form onSubmit={handleSubmit}>
        {message && <SuccessMessage>{message}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <input className='input'
          type="password"
          placeholder="Neues Passwort"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input className='input'
          type="password"
          placeholder="Passwort bestätigen"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button className='button' type="submit">Passwort zurücksetzen</button>
      </form>
      </Box>
    </Container>
  );
};

export default PasswordReset;

import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import apiUrl from './utils'; // Importieren Sie apiUrl
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei
import globalState from './globalState';


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

class ServiceManagement extends Component {

  state = {
    services: [],
    newservice: { name: "", description: "", price: "", status: "aktiv", store_email: globalState.email, custom_number: '', available: 0 },
    loading: true,
    error: null,
  };
  
  componentDidMount() {
    this.fetchServices();
  }

  fetchServices = async () => {
    this.setState({ loading: true });
    try {
      console.log("Fetching services");
      const response = await axios.get(`${apiUrl}/api/services/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log("Services fetched:", response.data);
      if (Array.isArray(response.data)) {
        this.setState({ services: response.data, loading: false });
      } else {
        throw new Error("API response is not an array");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      this.setState({ services: [], error: error.message, loading: false });
    }
  };

  handleInputChange = (e, id, field) => {
    const { services } = this.state;
    const updatedServices = services.map(service => {
      if (service.id === id) {
        return { ...service, [field]: e.target.value };
      }
      return service;
    });
    this.setState({ services: updatedServices });
  };

  handleNewInputChange = (e) => {
    const { newservice } = this.state;
    this.setState({ newservice: { ...newservice, [e.target.name]: e.target.value } });
  };

  handleSave = async (id) => {
    const service = this.state.services.find(service => service.id === id);
    try {
      console.log(`Updating service ${id} with data:`, service);
      await axios.put(`${apiUrl}/api/services/${id}`, service, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log('Service updated successfully.');
      alert('Service updated successfully.');
    } catch (error) {
      console.error("Error updating service:", error);
      alert('Failed to update service.');
    }
  };

  handleDelete = async (id) => {
    try {
      console.log(`Deleting service with id: ${id}`);
      await axios.delete(`${apiUrl}/api/services/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      this.setState({ services: this.state.services.filter(service => service.id !== id) });
      console.log('Service deleted successfully.');
      alert('Service deleted successfully.');
    } catch (error) {
      console.error("Error deleting service:", error);
      alert('Failed to delete service.');
    }
  };

  handleCreate = async () => {
    const { newservice } = this.state;
    try {
      console.log("Creating new service with data:", newservice);
      const response = await axios.post(`${apiUrl}/api/services/`, newservice, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      console.log('Service created successfully:', response.data);
      this.setState({
        newservice: { name: "", description: "", price: "", status: "aktiv", store_email: globalState.email, custom_number: '', available: 0 },
      });
      this.fetchServices();
      alert('Service created successfully.');
    } catch (error) {
      console.error("Error creating service:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      alert('Failed to create service.');
    }
  };

  render() {
    const { services, newservice, loading, error } = this.state;

    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error}</p>;
    }

    return (
      <div>
        <h2>Dienstleistungen</h2>
        <Table>
          <thead>
            <tr>
              <TableHeader>ID</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Beschreibung</TableHeader>
              <TableHeader>Preis</TableHeader>
              <TableHeader>Verfügbar</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Aktionen</TableHeader>
            </tr>
          </thead>
          <tbody>
            {services.map(service => (
              <TableRow key={service.id}>
                <TableCell>{service.id}</TableCell>
                <TableCell>
                  <Input
                    type="text"
                    value={service.name}
                    onChange={(e) => this.handleInputChange(e, service.id, 'name')}
                  />
                </TableCell>        
                <TableCell>
                  <Textarea
                    value={service.description}
                    rows={2}
                    onChange={(e) => this.handleInputChange(e, service.id, 'description')}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    value={service.price}
                    onChange={(e) => this.handleInputChange(e, service.id, 'price')}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    value={service.available}
                    onChange={(e) => this.handleInputChange(e, service.id, 'available')}
                  />
                </TableCell>                
                <TableCell>
                  <Select
                    value={service.status}
                    onChange={(e) => this.handleInputChange(e, service.id, 'status')}
                  >
                    <option value="aktiv">aktiv</option>
                    <option value="inaktiv">inaktiv</option>
                  </Select>
                </TableCell>
                <TableCell>
                  <button className='button' onClick={() => this.handleSave(service.id)}>Speichern</button>
                  {' '}
                  <button className='button' onClick={() => this.handleDelete(service.id)}>Löschen</button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Neu</TableCell>
              <TableCell>
                <Input
                  type="text"
                  name="name"
                  value={newservice.name}
                  onChange={this.handleNewInputChange}
                />
              </TableCell>              
              <TableCell>
                <Textarea
                  name="description"
                  rows={2}
                  value={newservice.description}
                  onChange={this.handleNewInputChange}
                />
              </TableCell>
              <TableCell>
                <Input
                  type="number"
                  name="price"
                  value={newservice.price}
                  onChange={this.handleNewInputChange}
                />
              </TableCell>
              <TableCell>
                <Input
                  type="number"
                  name="available"
                  value={newservice.available}
                  onChange={this.handleNewInputChange}
                />
              </TableCell>              
              <TableCell>
                <Select
                  name="status"
                  value={newservice.status}
                  onChange={this.handleNewInputChange}
                >
                  <option value="aktiv">aktiv</option>
                  <option value="inaktiv">inaktiv</option>
                </Select>
              </TableCell>
              <TableCell>
                <button className='button' onClick={this.handleCreate}>Erstellen</button>
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ServiceManagement;

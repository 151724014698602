import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './Auth';
import globalState from './globalState';
import { log } from './logger';
import {jwtDecode} from 'jwt-decode'; // Korrekt importieren

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [isGlobalAuthenticated, setIsGlobalAuthenticated] = useState(globalState.isAuthenticated);
  const [isLoading, setIsLoading] = useState(globalState.isLoading);
  const [isTokenValid, setIsTokenValid] = useState(null); // Setze den initialen Zustand auf null

  useEffect(() => {
    const checkTokenValidity = () => {
      const token = localStorage.getItem('access_token');

      if (!token) {
        console.log('Kein Token gefunden');
        setIsTokenValid(false); // Setze isTokenValid auf false, wenn kein Token vorhanden ist
        return;
      }

      try {
        // Decodiere das Token, um die Payload zu extrahieren
        const decodedToken = jwtDecode(token);

        // Überprüfe, ob das Token abgelaufen ist
        const currentTime = Date.now() / 1000; // Zeit in Sekunden
        if (decodedToken.exp < currentTime) {
          console.log('Token ist abgelaufen');
          setIsTokenValid(false); // Setze isTokenValid auf false, wenn das Token abgelaufen ist
        } else {
          console.log('Token ist gültig!');
          setIsTokenValid(true); // Setze isTokenValid auf true, wenn das Token gültig ist
        }
      } catch (error) {
        console.error('Fehler beim Decodieren des Tokens:', error);
        setIsTokenValid(false); // Setze isTokenValid auf false, wenn ein Fehler auftritt
      }
    };

    // Rufe die Funktion auf, um die Gültigkeit des Tokens zu überprüfen
    checkTokenValidity();

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    if (globalState.isLoading) {
      setIsLoading(true);
    } else if (!isAuthenticated && !globalState.isAuthenticated) {
      log('User is not authenticated, setting isGlobalAuthenticated to false');
      setIsGlobalAuthenticated(false);
      setIsLoading(false);
    } else {
      log('User is authenticated, setting isGlobalAuthenticated to true');
      setIsGlobalAuthenticated(true);
      setIsLoading(false);
    }

    return () => clearTimeout(timeout);
  }, [isAuthenticated, globalState.isLoading]);

  // Dieser useEffect wird aufgerufen, wenn isTokenValid sich ändert
  useEffect(() => {
    console.log('Token Gültigkeitsstatus geändert:', isTokenValid);
  }, [isTokenValid]);

  if (isLoading) {
    return <div>Loading...</div>; // Ladeanzeige, bis der Zustand geladen ist
  }

  if (isTokenValid === false) { // Überprüfe explizit auf false
    log('Redirecting to /login');
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Table, Container, Modal, Button } from 'react-bootstrap';
import apiUrl from './utils';
import './WasSchBer.css';
import { NotificationContext } from './NotificationContext';

function PriceAdjustments() {
    const { showNotification } = useContext(NotificationContext);
    const [priceChanges, setPriceChanges] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    useEffect(() => {
        // Abrufen der Preisänderungen vom Backend
        const fetchPriceChanges = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/price-changes/new`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                });
                setPriceChanges(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der Preisanpassungen:', error);
            }
        };

        fetchPriceChanges();
    }, []);

    const handleRowClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    const handlePriceUpdate = async () => {
        if (selectedProduct) {
            try {
                // PUT-Anfrage zur Preisaktualisierung
                await axios.put(`${apiUrl}/api/products/${selectedProduct.product_id}/price`,
                    {
                        price_new: selectedProduct.price_new,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        }
                    });

                // Erfolgsmeldung und Modal schließen
                showNotification('Preis erfolgreich aktualisiert!', 'success');

                // Entferne den geänderten Datensatz aus der Liste
                setPriceChanges((prevChanges) =>
                    prevChanges.filter((change) => change.product_id !== selectedProduct.product_id)
                );

                handleCloseModal();
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Preises:', error);
                showNotification('Fehler beim Aktualisieren des Preises.', 'error');
            }
        }
    };

    return (
        <Container>
            <h1>Preisanpassungen</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Produktname</th>
                        <th>Händlername</th>
                        <th>Aktueller Bestand</th>
                        <th>Alter Preis (€)</th>
                        <th>Neuer Preis (€)</th>
                        <th>Datum der Anfrage</th>
                    </tr>
                </thead>
                <tbody>
                    {priceChanges.length > 0 ? (
                        priceChanges.map((change) => (
                            <tr key={change.id} onClick={() => handleRowClick(change)}>
                                <td>{change.product_name}</td>
                                <td>{change.dealer_name}</td>
                                <td>{change.current_stock}</td>
                                <td>{change.price_old.toFixed(2)}</td>
                                <td>{change.price_new.toFixed(2)}</td>
                                <td>{new Date(change.created_at).toLocaleString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">Keine neuen Preisanpassungen verfügbar</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {/* Modal für die Preisanpassung */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Preisanpassung bestätigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Nach der Preisanpassung, musst du dich einmal neu in der Kasse anmelden und anschließend neue Etiketten für das Produkt ausdrucken.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={handlePriceUpdate}>
                        Preis anpassen
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default PriceAdjustments;

import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import './WasSchBer.css';
import apiUrl from './utils';
import globalState from './globalState';
import { useAuth } from './Auth';
import { useNavigate } from 'react-router';
const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [deliveryNotes, setDeliveryNotes] = useState([]);
  const [recentSales, setRecentSales] = useState([]);
  const [recentBillings, setRecentBillings] = useState([]);

  useEffect(() => {
    fetchDeliveryNotes();
    fetchRecentSales();
    fetchRecentBillings();
  }, []);

  const fetchDeliveryNotes = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/delivery_notes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      const filteredNotes = response.data.filter(note => note.status === 'Neu');
      setDeliveryNotes(filteredNotes);
    } catch (error) {
      console.error('Error fetching delivery notes:', error);
    }
  };

  const fetchRecentSales = async () => {
    try {
      const userId = globalState.userID;
      const response = await axios.get(`${apiUrl}/api/sales/merchant/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      const sortedSales = response.data.sort((a, b) => new Date(b.sale_date) - new Date(a.sale_date));
      setRecentSales(sortedSales.slice(0, 5));
    } catch (error) {
      console.error('Error fetching recent sales:', error);
    }
  };

  const fetchRecentBillings = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const response = await axios.post(`${apiUrl}/api/billing/dealer`, {
        month: 0,
        year: currentYear,
        dealer_id: globalState.userID,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      const sortedBillings = response.data.billing_data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setRecentBillings(sortedBillings.slice(0, 5));
    } catch (error) {
      console.error('Error fetching recent billings:', error);
    }
  };

  const formatDateTimeGerman = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formatDateGerman = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('de-DE');
  };

  const formatCurrencyGerman = (amount) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  const handleDeliveryNoteClick = (noteId) => {
    navigate(`/deliverynote-edit/${noteId}`);
  };

  return (
    <Container fluid className="mt-3">
      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header 
              style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => navigate('/settings')}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              User Informationen
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ textAlign: 'left', color: 'black' }}><strong>E-Mail:</strong> {user.email}</Card.Text>
              <Card.Text style={{ textAlign: 'left', color: 'black' }}><strong>User ID:</strong> {globalState.userID}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4">
            <Card.Header 
              style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => navigate('/deliverynote')}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              Offene Lieferscheine (nur die letzten 5)
            </Card.Header>
            <ListGroup variant="flush">
              {deliveryNotes.length > 0 ? (
                deliveryNotes.map(note => (
                  <ListGroup.Item 
                    key={note.id} 
                    style={{ textAlign: 'left', color: 'black', cursor: 'pointer' }}
                    onClick={() => handleDeliveryNoteClick(note.id)}
                  >
                    {note.store_name} - {formatDateGerman(note.delivery_date)} - Status: {note.status}
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item style={{ textAlign: 'left', color: 'black' }}>Keine offenen Lieferscheine</ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Header 
              style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => navigate(`/salesoverview?viewType=merchant&user_id=${globalState.userID}`)}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              Letzte Verkäufe (nur die letzten 5)
            </Card.Header>
            <ListGroup variant="flush">
              {recentSales.length > 0 ? (
                recentSales.map(sale => (
                  <ListGroup.Item key={sale.id} style={{ textAlign: 'left', color: 'black' }}>
                    {sale.product_name} - {formatDateTimeGerman(sale.sale_date)} - {formatCurrencyGerman(sale.total)}
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item style={{ textAlign: 'left', color: 'black' }}>Keine Verkäufe gefunden</ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4">
            <Card.Header 
              style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => navigate('/dealer-billing')}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              Letzte Abrechnungen (nur die letzten 5)
            </Card.Header>
            <ListGroup variant="flush">
              {recentBillings.length > 0 ? (
                recentBillings.map(billing => (
                  <ListGroup.Item key={billing.id} style={{ textAlign: 'left', color: 'black' }}>
                    {billing.store_name} - Rechnungsnr.: {billing.invoice_number} - {formatDateGerman(billing.created_at)} - {formatCurrencyGerman(billing.total_amount)}
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item style={{ textAlign: 'left', color: 'black' }}>Keine Abrechnungen gefunden</ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;

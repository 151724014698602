import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './Auth';
import globalState from './globalState';
import apiUrl from './utils';
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei

const StoreSettings = () => {
  const { user } = useAuth();
  
  const [settings, setSettings] = useState({
    user_id: null,
    store_name: '',
    store_description: '',
    street: '',
    house_number: '',
    zip_code: '',
    city: '',
    email: '',
    phone: '',
    status: 'New'
  });
  console.log(settings);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      if (user) {
        try {
          const response = await axios.post(`${apiUrl}/api/get-store-settings`, { user_id: globalState.userID }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
          });
          setSettings(response.data);
        } catch (error) {
          setError('Fehler beim Abrufen der Store-Einstellungen.');
        }
      }
    };

    fetchSettings();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    settings.user_id = globalState.userID;
    console.log(settings);
    try {
      await axios.post(`${apiUrl}/api/store-settings`, settings, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      setSuccess('Einstellungen erfolgreich gespeichert.');
    } catch (error) {
      setError('Fehler beim Speichern der Einstellungen.');
    }
  };

  return (
    <div className="settings-container">
      <h2>Store Einstellungen</h2>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <label>Mein Store-Status ist: {settings.status}</label>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Store-Name:</label>
          <input
            type="text"
            name="store_name"
            value={settings.store_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Store-Beschreibung:</label>
          <input
            type="text"
            name="store_description"
            value={settings.store_description}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Straße:</label>
          <input
            type="text"
            name="street"
            value={settings.street}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Hausnummer:</label>
          <input
            type="text"
            name="house_number"
            value={settings.house_number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>PLZ:</label>
          <input
            type="text"
            name="zip_code"
            value={settings.zip_code}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Stadt:</label>
          <input
            type="text"
            name="city"
            value={settings.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={settings.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Telefon:</label>
          <input
            type="tel"
            name="phone"
            value={settings.phone}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="button">Einstellungen speichern</button>
      </form>
    </div>
  );
};

export default StoreSettings;

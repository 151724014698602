import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './WasSchBer.css';
import apiUrl from './utils';
import globalState from './globalState';
import { NotificationContext } from './NotificationContext';

const InvoiceSettings = () => {
  const { showNotification } = useContext(NotificationContext);
  const [settings, setSettings] = useState({
    user_id: globalState.userID,
    prefix: '',
    year_format: 'YYYY',
    month_format: 'MM',
    number_length: 5,
    current_number: 1,
  });

  useEffect(() => {
    console.log(settings) 
    fetchSettings();
    console.log(settings) 
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/invoice_settings`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setSettings((prevSettings) => ({
        ...response.data,
        user_id: prevSettings.user_id, // Ensure user_id is not overwritten
      }));
      formatInvoiceNumber();
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({
      ...settings,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/invoice_settings`, settings, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      showNotification('Rechnungseinstellungen erfolgreich aktualisiert', 'success');
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const formatInvoiceNumber = () => {
    const { prefix, year_format, month_format, number_length, current_number } = settings;
    const year = year_format === 'YYYY' ? new Date().getFullYear() : year_format === 'YY' ? new Date().getFullYear().toString().slice(-2) : '';
    const month = month_format === 'MM' ? String(new Date().getMonth() + 1).padStart(2, '0') : month_format === 'M' ? new Date().getMonth() + 1 : '';
    const number = String(current_number).padStart(number_length, '0');

    let invoiceNumber = `${prefix}`;
    if (year) invoiceNumber += `-${year}`;
    if (month) invoiceNumber += `-${month}`;
    invoiceNumber += `-${number}`;

    return invoiceNumber;
  };

  return (
    <Container>
      <h1>Rechnungseinstellungen</h1>
      <div className="mt-3">
        <h5>Beispiel Rechnungsnummer:</h5>
        <p>{formatInvoiceNumber()}</p>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="prefix">
          <Form.Label>Präfix</Form.Label>
          <Form.Control
            type="text"
            name="prefix"
            value={settings.prefix}
            onChange={handleInputChange}
            maxLength="4"
          />
        </Form.Group>
        <Form.Group controlId="year_format">
          <Form.Label>Jahr Format</Form.Label>
          <Form.Control
            as="select"
            name="year_format"
            value={settings.year_format}
            onChange={handleInputChange}
          >
            <option value="None">Keine</option>
            <option value="YY">YY</option>
            <option value="YYYY">YYYY</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="month_format">
          <Form.Label>Monat Format</Form.Label>
          <Form.Control
            as="select"
            name="month_format"
            value={settings.month_format}
            onChange={handleInputChange}
          >
            <option value="None">Keine</option>
            <option value="M">M</option>
            <option value="MM">MM</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="number_length">
          <Form.Label>Mindestlänge der Rechnungsnummer</Form.Label>
          <Form.Control
            type="number"
            name="number_length"
            value={settings.number_length}
            onChange={handleInputChange}
            min="1"
          />
        </Form.Group>
        <Form.Group controlId="current_number">
          <Form.Label>Aktuelle Nummer</Form.Label>
          <Form.Control
            type="number"
            name="current_number"
            value={settings.current_number}
            onChange={handleInputChange}
            min="1"
          />
        </Form.Group>
        <button className='button' variant="primary" type="submit">
          Speichern
        </button>
      </Form>
    </Container>
  );
};

export default InvoiceSettings;

// src/components/ProductOverview.js

import React, { useState, useEffect, useContext } from 'react';
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import globalState from './globalState'; // Importieren Sie globalState
import apiUrl from './utils';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import { NotificationContext } from './NotificationContext';

const ProductOverview = () => {
  const { showNotification } = useContext(NotificationContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({ value: 'active', label: 'Aktiv' });
  const [priceFilter, setPriceFilter] = useState({ comparator: '', value: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const userId = globalState.userID;
  

  useEffect(() => {
    fetchProducts();
    fetchFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [selectedMerchant, selectedCategory, selectedStatus, priceFilter, products]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/products/store/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setProducts(response.data);
      setFilteredProducts(response.data); // Initialize filteredProducts with all products
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchFilters = async () => {
    try {
      const merchantResponse = await axios.get(`${apiUrl}/api/dealers/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setMerchants(merchantResponse.data);

      const categoryResponse = await axios.get(`${apiUrl}/api/categories/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setCategories(categoryResponse.data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  const applyFilters = () => {
    let filtered = products.filter(product => {
      const matchesMerchant = selectedMerchant ? product.merchant.id === selectedMerchant.value : true;
      const matchesCategory = selectedCategory ? product.category.id === selectedCategory.value : true;
      const matchesStatus = selectedStatus ? product.status === selectedStatus.value : true;
      const matchesPrice = priceFilter.value ? (
        priceFilter.comparator === '>'
          ? product.price > parseFloat(priceFilter.value)
          : priceFilter.comparator === '<'
          ? product.price < parseFloat(priceFilter.value)
          : true
      ) : true;

      return matchesMerchant && matchesCategory && matchesStatus && matchesPrice;
    });

    setFilteredProducts(filtered);
  };

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handlePriceComparatorChange = (e) => {
    setPriceFilter({ ...priceFilter, comparator: e.target.value });
  };

  const handlePriceValueChange = (e) => {
    setPriceFilter({ ...priceFilter, value: e.target.value });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <h1>Produktübersicht</h1>
      <Form>
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Händler</Form.Label>
              <Select
                options={merchants.map(merchant => ({
                  value: merchant.id,
                  label: `${merchant.first_name} ${merchant.last_name} - ${merchant.city} - ${merchant.email}`
                }))}
                value={selectedMerchant}
                onChange={handleMerchantChange}
                placeholder="Händler auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Kategorie</Form.Label>
              <Select
                options={categories.map(category => ({ value: category.id, label: category.name }))}
                value={selectedCategory}
                onChange={handleCategoryChange}
                placeholder="Kategorie auswählen..."
                isClearable
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Select
                options={[
                  { value: 'active', label: 'Aktiv' },
                  { value: 'inactive', label: 'Inaktiv' },
                ]}
                value={selectedStatus}
                onChange={handleStatusChange}
                placeholder="Status auswählen..."
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Preisfilter</Form.Label>
              <Row>
                <Col sm={4}>
                  <Form.Control as="select" value={priceFilter.comparator} onChange={handlePriceComparatorChange}>
                    <option value="">Wählen...</option>
                    <option value=">">&gt;</option>
                    <option value="<">&lt;</option>
                  </Form.Control>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    placeholder="Betrag"
                    value={priceFilter.value}
                    onChange={handlePriceValueChange}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <th>Händler</th>
            <th>Produktname</th>
            <th>Produktbeschreibung</th>
            <th>Preis</th>
            <th>Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {paginatedProducts.map((product) => (
            <tr key={product.id}>
              <td>{`${product.merchant.first_name} ${product.merchant.last_name} - ${product.merchant.city}`}</td>
              <td>{product.name}</td>
              <td>{product.description}</td>
              <td>{product.price.toFixed(2)} €</td>
              <td>{product.status === 'active' ? 'Aktiv' : 'Inaktiv'}</td>
              <td>
                {product.has_photo ? (
                  <button className='button' onClick={() => window.open(`${apiUrl}/api/products/${product.id}/photo`, '_blank')}>
                    Foto anzeigen
                  </button>
                ) : null}{' '}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination">
        {[...Array(Math.ceil(filteredProducts.length / itemsPerPage)).keys()].map(page => (
          <button className='button'
            key={page + 1}
            onClick={() => handlePageChange(page + 1)}
            active={currentPage === page + 1}
          >
            {page + 1}
          </button>
        ))}
      </div>
    </Container>
  );
};

export default ProductOverview;

import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import apiUrl from './utils'; // Importieren Sie apiUrl
import './WasSchBer.css';  // Importieren Sie die Stylesheet-Datei

const StoreTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  margin-right: 10px;
  padding: 5px;
`;

const PaginationButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  background-color: #022f40; // Elementfarbe
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #76B9AF; // Hover-Farbe
  }
`;

class StoreManagement extends Component {
  state = {
    stores: [],
    loading: true,
    error: null,
    search: '',
    currentPage: 1,
    storesPerPage: 25,
  };

  componentDidMount() {
    this.fetchStores();
  }

  fetchStores = async () => {
    this.setState({ loading: true });
    try {
      const { search, currentPage, storesPerPage } = this.state;
      const response = await axios.get(`${apiUrl}/api/admin/stores`, {
        params: { search },
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      this.setState({ stores: response.data, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.fetchStores();
  };

  handleCreateDatabase = async (userId) => {
    try {
      await axios.post(`${apiUrl}/api/create-store-database`, { user_id: userId }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      alert('Datenbank erfolgreich erstellt.');
      this.fetchStores();
    } catch (error) {
      alert('Fehler beim Erstellen der Datenbank.');
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, this.fetchStores);
  };

  render() {
    const { stores, loading, error, search, currentPage, storesPerPage } = this.state;
    const indexOfLastStore = currentPage * storesPerPage;
    const indexOfFirstStore = indexOfLastStore - storesPerPage;
    const currentStores = stores.slice(indexOfFirstStore, indexOfLastStore);
    const totalPages = Math.ceil(stores.length / storesPerPage);

    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error}</p>;
    }

    return (
      <div>
        <h2>Store Management</h2>
        <SearchContainer>
          <form onSubmit={this.handleSearchSubmit}>
            <SearchInput
              type="text"
              placeholder="Search by store name, address, email, or phone"
              value={search}
              onChange={this.handleSearchChange}
            />
            <button className='button' type="submit">Suchen</button>
          </form>
        </SearchContainer>
        <StoreTable>
          <thead>
            <tr>
              <TableHeader>Store Name</TableHeader>
              <TableHeader>Adresse</TableHeader>
              <TableHeader>E-Mail</TableHeader>
              <TableHeader>Telefon</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Aktionen</TableHeader>
            </tr>
          </thead>
          <tbody>
            {currentStores.map(store => (
              <TableRow key={store.user_id}>
                <TableCell>{store.store_name}</TableCell>
                <TableCell>{store.street} {store.house_number}, {store.zip_code} {store.city}</TableCell>
                <TableCell>{store.email}</TableCell>
                <TableCell>{store.phone}</TableCell>
                <TableCell>{store.status}</TableCell>
                <TableCell>
                  {store.status === 'New' && (
                    <button className="button" onClick={() => this.handleCreateDatabase(store.user_id)}>Datenbank erstellen</button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </StoreTable>
        <div>
          {[...Array(totalPages)].map((_, i) => (
            <PaginationButton key={i} onClick={() => this.handlePageChange(i + 1)}>
              {i + 1}
            </PaginationButton>
          ))}
        </div>
      </div>
    );
  }
}

export default StoreManagement;

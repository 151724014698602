import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import './App.css'; // Import the previous style
import apiUrl from './utils';

const AgreementList = () => {
    const [agreements, setAgreements] = useState([]);
    const [selectedAgreement, setSelectedAgreement] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        street: '',
        postal_code: '',
        city: '',
        email: '',
        phone: '',
        agreement_start: '',
        agreement_end: null,
        duration: 0,
        cancellation_period: 0,
        payment_method: '',
        paypal_address: '',
        bank_account_iban: '',
        bank_account_bic: '',
        bank_account_holder: '',
        is_vat_registered: false,
    });

    useEffect(() => {
        fetchAgreements();
    }, []);

    const fetchAgreements = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/agreements`);
            setAgreements(response.data);
        } catch (error) {
            console.error('Error fetching agreements:', error);
        }
    };

    const handleShowModal = (agreement) => {
        setSelectedAgreement(agreement);
        if (agreement) {
            setFormData(agreement);
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            first_name: '',
            last_name: '',
            company_name: '',
            street: '',
            postal_code: '',
            city: '',
            email: '',
            phone: '',
            agreement_start: '',
            agreement_end: null,
            duration: 0,
            cancellation_period: 0,
            payment_method: '',
            paypal_address: '',
            bank_account_iban: '',
            bank_account_bic: '',
            bank_account_holder: '',
            is_vat_registered: false,
        });
        setSelectedAgreement(null);
    };

    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (selectedAgreement) {
                await axios.put(`${apiUrl}/api/agreements/${selectedAgreement.id}`, formData);
            } else {
                await axios.post(`${apiUrl}/api/agreements`, formData);
            }
            fetchAgreements();
            handleCloseModal();
        } catch (error) {
            console.error('Error saving agreement:', error);
        }
    };

    return (
        <div className="dashboard-container">
            <h2>Agreements</h2>
            <Button variant="primary" onClick={() => handleShowModal(null)}>
                Add Agreement
            </Button>
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Last Name</th>
                        <th>First Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Duration</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {agreements.map((agreement) => (
                        <tr key={agreement.id} onClick={() => handleShowModal(agreement)}>
                            <td>{agreement.id}</td>
                            <td>{agreement.last_name}</td>
                            <td>{agreement.first_name}</td>
                            <td>{agreement.email}</td>
                            <td>{agreement.phone}</td>
                            <td>{new Date(agreement.agreement_start).toLocaleDateString('de-DE')}</td>
                            <td>{agreement.agreement_end ? new Date(agreement.agreement_end).toLocaleDateString('de-DE') : ''}</td>
                            <td>{agreement.duration}</td>
                            <td>
                                <Button variant="secondary" onClick={() => handleShowModal(agreement)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedAgreement ? 'Edit Agreement' : 'Add Agreement'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formCompanyName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStreet">
                            <Form.Label>Street</Form.Label>
                            <Form.Control
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPostalCode">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="postal_code"
                                value={formData.postal_code}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formAgreementStart">
                            <Form.Label>Agreement Start</Form.Label>
                            <Form.Control
                                type="date"
                                name="agreement_start"
                                value={formData.agreement_start}
                                onChange={handleFormChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formAgreementEnd">
                            <Form.Label>Agreement End</Form.Label>
                            <Form.Control
                                type="date"
                                name="agreement_end"
                                value={formData.agreement_end || ''}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formDuration">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control
                                type="number"
                                name="duration"
                                value={formData.duration}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCancellationPeriod">
                            <Form.Label>Cancellation Period (Weeks)</Form.Label>
                            <Form.Control
                                type="number"
                                name="cancellation_period"
                                value={formData.cancellation_period}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPaymentMethod">
                            <Form.Label>Payment Method</Form.Label>
                            <Form.Control
                                as="select"
                                name="payment_method"
                                value={formData.payment_method}
                                onChange={handleFormChange}
                                required
                            >
                                <option value="">Select...</option>
                                <option value="PayPal">PayPal</option>
                                <option value="Bank">Bank</option>
                            </Form.Control>
                        </Form.Group>
                        {formData.payment_method === 'PayPal' && (
                            <Form.Group controlId="formPaypalAddress">
                                <Form.Label>PayPal Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="paypal_address"
                                    value={formData.paypal_address}
                                    onChange={handleFormChange}
                                />
                            </Form.Group>
                        )}
                        {formData.payment_method === 'Bank' && (
                            <>
                                <Form.Group controlId="formBankAccountIBAN">
                                    <Form.Label>Bank Account IBAN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bank_account_iban"
                                        value={formData.bank_account_iban}
                                        onChange={handleFormChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBankAccountBIC">
                                    <Form.Label>Bank Account BIC</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bank_account_bic"
                                        value={formData.bank_account_bic}
                                        onChange={handleFormChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBankAccountHolder">
                                    <Form.Label>Bank Account Holder</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bank_account_holder"
                                        value={formData.bank_account_holder}
                                        onChange={handleFormChange}
                                    />
                                </Form.Group>
                            </>
                        )}
                        <Form.Group controlId="formIsVATRegistered">
                            <Form.Check
                                type="checkbox"
                                label="Is VAT Registered"
                                name="is_vat_registered"
                                checked={formData.is_vat_registered}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {selectedAgreement ? 'Save Changes' : 'Add Agreement'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AgreementList;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Container, Button, Table, Modal, Form } from 'react-bootstrap';
import { useAuth } from './Auth';
import apiUrl from './utils';

function DealerManagement() {
  const { user } = useAuth();
  const [dealers, setDealers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [contactId, setContactId] = useState('');

  useEffect(() => {
    fetchDealers();
  }, []);

  const fetchDealers = async () => {
    const response = await axios.get(`${apiUrl}/api/dealers/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    });
    setDealers(response.data);
  };

  // Modal anzeigen und den ausgewählten Händler setzen
  const handleAssignContact = (dealer) => {
    setSelectedDealer(dealer);
    setShowModal(true);
  };

  // Modal schließen
  const handleCloseModal = () => {
    setShowModal(false);
    setContactId('');
    setSelectedDealer(null);
  };

  // API-Aufruf, um die lexoffice_contact_id zu aktualisieren
  const handleSaveContactId = async () => {
    if (selectedDealer) {
      try {
        await axios.put(`${apiUrl}/api/dealers/update/lexoffice_contactid`, {
          dealer_userid: selectedDealer.dealer_userid,
          lexoffice_contact_id: contactId
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        // Erfolgreich gespeichert, Händlerliste aktualisieren
        fetchDealers();
        handleCloseModal();
      } catch (error) {
        console.error('Error updating lexoffice contact ID:', error);
      }
    }
  };

  return (
    <Container>
      <h1>Händlerübersicht</h1>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Firmenname</th>
            <th>Straße</th>
            <th>Hausnummer</th>
            <th>PLZ</th>
            <th>Stadt</th>
            <th>E-Mail</th>
            <th>Mobil</th>
            <th>Lexoffice Kontakt</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {dealers.map((dealer, index) => (
            <tr key={index}>
              <td>{dealer.first_name}</td>
              <td>{dealer.last_name}</td>
              <td>{dealer.company_name}</td>
              <td>{dealer.street}</td>
              <td>{dealer.house_number}</td>
              <td>{dealer.postal_code}</td>
              <td>{dealer.city}</td>
              <td>{dealer.email}</td>
              <td>{dealer.phone}</td>
              <td>
                {dealer.lexoffice_contactid || 'Nicht zugewiesen'}
              </td>
              <td>
                {!dealer.lexoffice_contactid && (
                  <Button variant="primary" onClick={() => handleAssignContact(dealer)}>
                    Lexoffice Kontakt zuweisen
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal zum Zuweisen der Lexoffice Contact ID */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Lexoffice Kontakt zuweisen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Lexoffice Contact ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Lexoffice Contact ID"
                value={contactId}
                onChange={(e) => setContactId(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSaveContactId}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default DealerManagement;

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from './Modal'; // Importiere die Modal-Komponente

const FooterContainer = styled.footer`
  background-color: #022f40;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  margin-top: auto;
`;

const FooterLink = styled.button`
  background: none;
  border: none;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
`;

const ExternalLink = styled.a`
  background: none;
  border: none;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
`;

const ImpressumText = styled.div`
  h2 {
    text-align: center;
  }
  p {
    text-align: justify;
    line-height: 1.5;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin-bottom: 10px;
    }
  }
`;

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <FooterContainer>
      &copy; 2024 WasSchBer GmbH & Co. KG{' '}
      <ExternalLink href="https://wasschber.de/impressum-datenschutz/" target="_blank" rel="noopener noreferrer">Impressum</ExternalLink>{' '}
      <ExternalLink href="https://wasschber.de/impressum-datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz</ExternalLink>{' '}
      <ExternalLink href="https://wasschber.de/impressum-datenschutz/" target="_blank" rel="noopener noreferrer">AGB</ExternalLink>{' '}
    </FooterContainer>
  );
};

export default Footer;

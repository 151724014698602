import React, { useState, useEffect } from 'react';
import { Container, Table, ButtonGroup, Button, Pagination, Alert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './WasSchBer.css'; // Importieren Sie die Stylesheet-Datei
import { useNavigate } from 'react-router-dom';
import { formatDateGerman } from './utils'; // Importieren der Formatierungsfunktion für die Darstellung
import axios from 'axios';
import apiUrl from './utils'; // Importieren der API-URL
import globalState from './globalState'; // Importieren des globalen Zustands

const DeliveryNoteOverview = () => {
  const [deliveryNotes, setDeliveryNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filters, setFilters] = useState(['Neu', 'In Bearbeitung']);
  const [loading, setLoading] = useState(true);
  const [hasActiveAgreement, setHasActiveAgreement] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDeliveryNotes();
    checkActiveAgreements();
  }, []);

  const fetchDeliveryNotes = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await axios.get(`${apiUrl}/api/delivery_notes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      console.log(data);
      setDeliveryNotes(data);
      setFilteredNotes(data.filter(note => filters.includes(note.status)));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching delivery notes:', error);
      setLoading(false);
    }
  };

  const checkActiveAgreements = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/agreements/dealer/${globalState.userID}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      const agreements = response.data;

      // Prüfen, ob es aktive oder zukünftige Vereinbarungen gibt
      const hasActiveOrFutureAgreement = agreements.some(
        (agreement) => agreement.status === 'aktiv' || agreement.status === 'bestellt'
      );
      setHasActiveAgreement(hasActiveOrFutureAgreement);
    } catch (error) {
      console.error('Error checking active agreements:', error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/deliverynote-edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/deliverynote-edit/${id}?readonly=true`);
  };

  const handleFilterClick = (status) => {
    setFilters((prevFilters) =>
      prevFilters.includes(status)
        ? prevFilters.filter((filter) => filter !== status)
        : [...prevFilters, status]
    );
  };

  const handleCreateNew = () => {
    navigate('/deliverynote-create');
  };

  useEffect(() => {
    applyFilters();
  }, [filters, deliveryNotes]);

  const applyFilters = () => {
    setFilteredNotes(deliveryNotes.filter(note => filters.includes(note.status)));
    setCurrentPage(1); // Reset to first page on filter change
  };

  const getStatusVariant = (status) => {
    switch (status) {
      case 'Neu':
        return 'primary';
      case 'In Bearbeitung':
        return 'warning';
      case 'Abgeschlossen':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Logic for displaying current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNotes.slice(indexOfFirstItem, indexOfLastItem);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredNotes.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination className="custom-pagination">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {pageNumbers.map((number) => (
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageNumbers.length} />
        <Pagination.Last onClick={() => handlePageChange(pageNumbers.length)} disabled={currentPage === pageNumbers.length} />
      </Pagination>
    );
  };

  return (
    <Container>
      <h1>Lieferscheinübersicht</h1>
      <div className="mb-3">
        <button
          className='button'
          variant="success"
          onClick={handleCreateNew}
          disabled={!hasActiveAgreement}
        >
          Neuen Lieferschein anlegen
        </button>
        {!hasActiveAgreement && (
          <Alert variant="warning" className="mt-2">
            Lieferscheine können nur mit einer aktiven oder zukünftigen Vereinbarung erstellt werden.
          </Alert>
        )}
        <p></p>
        <div>
          <strong>Status-Filter: </strong>
          <ButtonGroup>
            <Button
              variant={filters.includes('Neu') ? 'primary' : 'outline-primary'}
              onClick={() => handleFilterClick('Neu')}
            >
              Neu
            </Button>
            <Button
              variant={filters.includes('In Bearbeitung') ? 'warning' : 'outline-warning'}
              onClick={() => handleFilterClick('In Bearbeitung')}
            >
              In Bearbeitung
            </Button>
            <Button
              variant={filters.includes('Abgeschlossen') ? 'success' : 'outline-success'}
              onClick={() => handleFilterClick('Abgeschlossen')}
            >
              Abgeschlossen
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Table bordered className="mt-3">
        <thead>
          <tr>
            <th>Lieferschein-Nr.</th>
            <th>Lieferdatum</th>
            <th>Store</th>
            <th>Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: 25 }).map((_, index) => (
              <tr key={index}>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
              </tr>
            ))
          ) : (
            currentItems.map((note) => (
              <tr key={note.id}>
                <td data-label="ID">{note.id}</td>
                <td data-label="Lieferdatum">{formatDateGerman(note.delivery_date)}</td>
                <td data-label="Store">{note.store_name}</td>
                <td data-label="Status">
                  <Button variant={getStatusVariant(note.status)} disabled>
                    {note.status}
                  </Button>
                </td>
                <td data-label="Aktionen">
                  {note.status === 'Neu' && (
                    <button className='button' variant="warning" onClick={() => handleEdit(note.id)}>Bearbeiten</button>
                  )}
                  {(note.status === 'In Bearbeitung' || note.status === 'Abgeschlossen') && (
                    <button className='button' variant="info" onClick={() => handleView(note.id)}>Anzeigen</button>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {!loading && renderPagination()}
    </Container>
  );
};

export default DeliveryNoteOverview;

// src/CookieConsentModal.js
import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

const ModalHeader = styled.h2`
  margin: 0 0 15px 0;
`;

const ModalText = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => (props.primary ? '#4CAF50' : '#f44336')};
  color: white;

  &:hover {
    opacity: 0.8;
  }
`;

const CookieConsentModal = ({ onAccept, onDecline }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>Cookie-Einwilligung</ModalHeader>
        <ModalText>
          Diese Website verwendet Cookies, um Ihr Nutzererlebnis zu verbessern und Informationen über das Nutzerverhalten zu sammeln.
          Es werden keine persönlichen Daten gespeichert. Bitte stimmen Sie der Verwendung von Cookies zu.
        </ModalText>
        <ButtonGroup>
          <Button primary onClick={onAccept}>
            Akzeptieren
          </Button>
          <Button onClick={onDecline}>Ablehnen</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CookieConsentModal;

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from './Auth';
import { jwtDecode } from 'jwt-decode'; // Korrigierter Import
import apiUrl from './utils';
import { log } from './logger';
let userId = null;
let isAuthorized = false;

const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);

export const getisAuthorized = () => {
  return isAuthorized;
};

const globalState = {
  isAuthenticated: false,
  isLoading: true,  // Neuer Zustand für das Laden hinzufügen
  userID: null,
  isAdmin: false,
  email: null,
  user_type: null,
  async setUserID() {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.isAuthenticated = false;
        this.isLoading = false;  // Ladezustand auf false setzen
        log('No token found, setting isAuthenticated to false');
        return;
      }
      const response = await axios.get(`${apiUrl}/api/dashboard/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.userID = response.data.user_id;
      this.isAdmin = response.data.isAdmin;
      this.email = response.data.email;
      this.user_type = response.data.user_type;
      this.isAuthenticated = true;
      this.isLoading = false;  // Ladezustand auf false setzen
      log('UserID set', { userID: this.userID, isAdmin: this.isAdmin, isAuthenticated: this.isAuthenticated });
    } catch (error) {
      console.error('Error fetching UserID:', error);
      this.isAuthenticated = false;
      this.isLoading = false;  // Ladezustand auf false setzen
      log('Error fetching UserID, setting isAuthenticated to false', { error });
    }
  },
};

export default globalState;
/* 
export const setUserId = (email) => {
  userId = SetUserID();
};
const SetUserID = () => {
  const [userDataFix, setUserData] = useState({ email: '', user_id: '' });
  const [error, setError] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:8000/dashboard', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        setUserData(response.data);

      } catch (error) {
        setError('Error fetching user data');
      }
    };

    fetchData();
  }, []);



  return userDataFix.email

} */
export function formatEuro(amount) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  }
  
const apiUrl = process.env.REACT_APP_API_URL;
console.log(apiUrl)
export default apiUrl;

// Funktion zur Umwandlung in deutsches Datumsformat
const formatDateGerman = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export {formatDateGerman};

export const openPdf = (pdfBase64) => {
  const byteCharacters = atob(pdfBase64);
  const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
};
import React, { Component } from 'react';
import { Link, Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from './Auth';
import UserManagement from './Admin_UserManagement';
import apiUrl from './utils';
import CategoryManagement from './Admin_CategoryManagement';
import StoreManagement from './Admin_StoreOverview';
import LogsLogin from './Admin_LogsLogin';

const AdminNav = styled.nav`
  background: #333;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const AdminNavItem = styled(Link)`
  color: white;
  margin: 5px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

class AdminDashboard extends Component {
  render() {
    return (
      <div>
        <AdminNav>
          <AdminNavItem to="/admin/users">Benutzerverwaltung</AdminNavItem>
          <AdminNavItem to="/admin/stores">Store-Übersicht</AdminNavItem>
          <AdminNavItem to="/admin/categories">Anlage von Produktkategorien</AdminNavItem>
          <AdminNavItem to="/admin/login-attempts">Logins von Benutzern</AdminNavItem>
        </AdminNav>
        <div>
          <Routes>
            <Route path="users" element={<UserManagement />} />
            <Route path="stores" element={<StoreManagement />} />
            <Route path="categories" element={<CategoryManagement />} />
            <Route path="login-attempts" element={<LogsLogin />} />
          </Routes>
        </div>
      </div>
    );
  }
}

const AdminRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user || (!user.email.endsWith('@wasschber.de') && !user.email.endsWith('@schmidt-it.services'))) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default AdminDashboard;
